import {useGlobus} from '../GlobusProvider';

import OverlayButtonGroupSpacer from './OverlayButtonGroupSpacer';
import OverlayButtonGroup from './OverlayButtonGroup';
import WestermannLogo, {bottomPaddingWestermannLogo, topWestermannLogo} from './WestermannLogo';
import ZoomInButton from './ZoomInButton';
import ZoomOutButton from './ZoomOutButton';
import OverlayPosition from './OverlayPosition';
import HeadToNorthButton from './HeadToNorthButton';
// import TerrainButton from './TerrainButton';
import LookDownwardsButton from './LookDownwardsButton';
import OverlayPopoverButton from './OverlayPopoverButton';
import DefaultMovementButton, {ICON_BY_DEFAULT_MOVEMENT_MODE} from './DefaultMovementButton';
import TerrainButton from "./TerrainButton";

type GlobusOverlayProps = {
  imageName?: string;
};
const GlobusOverlay: React.FC<GlobusOverlayProps> = ({imageName}) => {
  const {defaultMovementMode} = useGlobus();
  return (
    <div className="relative w-full h-full">
      <div className="absolute left-0 right-0 top-0">
        <OverlayPosition imageName={imageName} />
      </div>

      <div
        className="absolute right-0 bottom-0 flex flex-col gap-3 items-end mr-5 mb-[10px]"
        style={{bottom: topWestermannLogo + 'px'}}
      >
        <OverlayButtonGroup className="hidden md:flex" orientation="y">
          <ZoomInButton />
          <OverlayButtonGroupSpacer />
          <ZoomOutButton />
          <OverlayButtonGroupSpacer />
          <OverlayPopoverButton
            icon={defaultMovementMode !== null ? ICON_BY_DEFAULT_MOVEMENT_MODE[defaultMovementMode] : 'Bewegen'}
          >
            <DefaultMovementButton mode="rotate" />
            <DefaultMovementButton mode="zoom" />
            <DefaultMovementButton mode="tilt" />
            <DefaultMovementButton mode="look" />
          </OverlayPopoverButton>
          <OverlayButtonGroupSpacer />
          <HeadToNorthButton />
          <OverlayButtonGroupSpacer />
          <LookDownwardsButton />
          <OverlayButtonGroupSpacer />
          <TerrainButton />
        </OverlayButtonGroup>
      </div>
      <div className="fixed bg-white bottom-0 right-0 mr-5" style={{paddingBottom: bottomPaddingWestermannLogo + 'px'}}>
        <WestermannLogo />
      </div>
    </div>
  );
};

export default GlobusOverlay;
