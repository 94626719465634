export type ReturnIsOr<T extends (val: any) => val is any> = T extends (val: any) => val is infer R ? R : any;

export function isOr<F extends ReadonlyArray<(val: any) => val is any>>(
  ...fs: F
): (val: any) => val is ReturnIsOr<F[number]> {
  return (val): val is ReturnIsOr<F[number]> => {
    for (const f of fs) {
      if (f(val)) {
        return true;
      }
    }

    return false;
  };
}

export type ReturnIsOneOf<T extends ReadonlyArray<number | string | boolean>> = (val: any) => val is T[number];

/** Matches agains literals */
export function isOneOf<T extends ReadonlyArray<number | string | boolean>>(...expected: T): ReturnIsOneOf<T> {
  const isOneOfFunc: ReturnIsOneOf<T> = (val): val is T[number] => {
    for (const exp of expected) {
      if (exp === val) {
        return true;
      }
    }
    return false;
  };

  return isOneOfFunc;
}

export function createIsArrayWithItems<T>(
  isItemFn: (item: unknown) => item is T
): (value: unknown) => value is Array<T> {
  return function isArrayWithItems(value: unknown): value is Array<T> {
    if (!Array.isArray(value)) {
      return false;
    }

    return value.every(isItemFn);
  };
}

export function ensureIs<T, U extends T>(val: T, f: (val: T) => val is U, msg: string = 'Ensure-is failed'): U {
  if (!f(val)) {
    throw new Error(msg);
  }
  return val;
}

export default function isNonNullable<T>(val: T | null | undefined | void): val is T {
  return val !== null && val !== undefined;
}
