import {ElementType, Ref} from 'react';

import Icon, {IconProps} from './Icon';
import Popover, {PopoverAlign, PopoverPosition, PopoverProps} from './Popover';

export type ButtonProps<T extends keyof HTMLElementTagNameMap & keyof JSX.IntrinsicElements = 'button'> = {
  as?: ElementType;
  active?: boolean;
  icon?: IconProps['name'];
  iconSize?: IconProps['size'];
  iconOverlay?: IconProps['overlay'];
  iconOverlayPosition?: IconProps['overlayPosition'];
  iconOverlayRotation?: IconProps['overlayRotation'];
  iconOverlayClassName?: IconProps['overlayClassName'];
  iconClassName?: IconProps['className'];
  labelOptional?: boolean;
  buttonRef?: Ref<HTMLElementTagNameMap[T]>;
  isPopoverOpen?: boolean;
  popoverContent?: JSX.Element;
  popoverAlign?: PopoverAlign;
  popoverPosition?: PopoverPosition;
  onPopoverClickOutside?: PopoverProps['onClickOutside'];
} & React.ComponentPropsWithoutRef<T>;

const Button = <K extends keyof HTMLElementTagNameMap & keyof JSX.IntrinsicElements = 'button'>({
  as: T = 'button',
  children,
  active = false,
  icon,
  labelOptional = true,
  iconSize,
  iconOverlay,
  iconOverlayPosition,
  iconOverlayRotation,
  iconOverlayClassName,
  iconClassName,
  isPopoverOpen = false,
  popoverContent,
  popoverAlign,
  popoverPosition,
  onPopoverClickOutside,
  className = '',
  buttonRef,
  ...rest
}: ButtonProps<K>) => {
  const content = (
    <T
      ref={buttonRef}
      className={`flex items-center cursor-pointer select-none border-solid ${className} ${
        active ? 'bg-gray-400/90' : ''
      }`}
      {...rest}
    >
      {icon ? (
        <Icon
          name={icon}
          size={iconSize}
          overlay={iconOverlay}
          className={iconClassName}
          overlayPosition={iconOverlayPosition}
          overlayRotation={iconOverlayRotation}
          overlayClassName={iconOverlayClassName}
        />
      ) : null}
      {children ? (
        <span className={`px-2 ${labelOptional && icon ? 'pl-1 hidden md:block' : ''}`}>{children}</span>
      ) : null}
    </T>
  );

  if (popoverContent !== undefined) {
    return (
      <Popover
        isOpen={isPopoverOpen}
        content={popoverContent}
        align={popoverAlign}
        position={popoverPosition}
        onClickOutside={onPopoverClickOutside}
      >
        {content}
      </Popover>
    );
  } else {
    return content;
  }
};

export default Button;
