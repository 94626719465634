function normalizeBaseUrl(url: string): URL {
  return new URL(url, window.location.href);
}

export const BASE_URL = normalizeBaseUrl((process.env.PUBLIC_URL ?? '').replace(/\/?$/, '/'));

export function getAuthBaseUrl(): string {
  return process.env.REACT_APP_OAUTH ?? '';
}

export function getBaseName(): string {
  return BASE_URL.pathname;
}

export function getApiBase(): string {
  return process.env.REACT_APP_API_BASE_URL ?? '';
}

export function getKmzUrl(kmzId: number): string {
  return `${getApiBase()}material/kmz/dgo_${kmzId}.kmz`;
}

export function getLegendeImg(kmzId: number): string {
  return `${getApiBase()}material/kmz/legend/dgo_${kmzId}_leg.jpg`;
}
