import React, {useState} from 'react';
import Modal from 'react-modal';
// import {useTranslation} from 'react-i18next';

import TopBarButton from '../top-bar/TopBarButton';

import Search from './Search';

const SearchButton: React.FC = () => {
  // const {t} = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <>
      <TopBarButton
        // TODO: title={t('atlas:mix.search')}
        icon="Suche"
        onClick={() => setOpen(true)}
      />
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        // TODO: contentLabel={t('atlas:mix.search')}
        className="absolute top-0 right-0 bottom-0 w-full h-full max-w-[632px] min-w-[320px] p-[5px]"
        overlayClassName="fixed inset-0 bg-diercke-blue/50"
      >
        <Search onClose={() => setOpen(false)} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Modal>
    </>
  );
};

export default SearchButton;
