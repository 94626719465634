import {useCallback, useEffect, useRef} from 'react';
import * as Cesium from 'cesium';
import {CesiumComponentRef, KmlDataSource, useCesium} from 'resium';

import {getKmzUrl} from '../../modules/url';

import {useGlobus} from './GlobusProvider';

const KmlMap: React.FC = () => {
  const {viewer, scene} = useCesium();
  const dataSourceRef = useRef<CesiumComponentRef<Cesium.KmlDataSource>>(null);
  const opacityRef = useRef(1);

  const {overlayMap} = useGlobus();
  const kmzId = overlayMap?.id ?? null;

  const isVisible = overlayMap?.isVisible ?? false;
  const opacity = overlayMap?.opacity ?? 1;

  const onLoad = useCallback(() => {
    const dataSource = dataSourceRef.current?.cesiumElement;
    if (!viewer || !dataSource || !scene) return;

    // Apply callback color to map
    dataSource.entities.suspendEvents();
    for (let entity of dataSource.entities.values) {
      const rectangle = entity.rectangle;
      if (rectangle && rectangle.material instanceof Cesium.ImageMaterialProperty) {
        rectangle.material.color = new Cesium.CallbackProperty(
          () => Cesium.Color.WHITE.withAlpha(opacityRef.current ** 1.5),
          false
        );
      }
    }
    dataSource.entities.resumeEvents();
    scene.requestRender();
  }, [viewer, scene]);

  useEffect(() => {
    opacityRef.current = opacity;
    scene?.requestRender();
  }, [scene, opacity]);

  useEffect(() => {
    scene?.requestRender();
  }, [scene, isVisible]);

  if (kmzId === null) return null;

  return <KmlDataSource ref={dataSourceRef} data={getKmzUrl(kmzId)} show={isVisible} onLoad={onLoad} />;
};

export default KmlMap;
