type Props = {
  onToggle(): void;
  isOpen: boolean;
};

const GlobusDrawerToggle: React.FC<Props> = ({onToggle, isOpen}) => (
  <button
    className="
      w-[51px] h-[73px]
      flex items-center justify-center
      bg-diercke-blue-50
      border border-l-0 border-solid border-r-diercke-blue
      rounded-r-xl
    "
    onClick={onToggle}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      className={`transition-transform ${isOpen ? '' : 'rotate-180'}`}
    >
      <polygon className="fill-diercke-blue" points="0 8 16 16 16 0" />
    </svg>
  </button>
);

export default GlobusDrawerToggle;
