import React, {ReactNode} from 'react';

import Icon from './Icon';


type Props = {
  header: string;
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  children?: ReactNode;
};
const Dialog = (props: Props) => {
  return (
    <div className='flex flex-col h-full rounded-md'>
      <h2 className="relative flex items-center bg-diercke-blue-300  rounded-t-md text-diercke-blue">
        <span className="px-1 py-3 text-center font-semibold grow ">{props.header}</span>
        {!!props.onClose && (
          <button className="absolute right-0" type="reset" onClick={props.onClose}>
            <Icon name="Schliesskreuz-02" className="text-diercke-blue" />
          </button>
        )}
      </h2>
      {/*<div className={'border-8 border-white rounded-b-md'}>*/}
        {props.children}
      {/*</div>*/}
    </div>
  );
};

export default Dialog;
