const colors = require('tailwindcss/colors');

/** @satisfies {import('tailwindcss').Config} */
const config = {
  content: ['./public/**/*.html', './src/**/*.{html,ts,tsx}'],
  theme: {
    // Kept in sync with ionic breakpoints
    // (see https://ionicframework.com/docs/layout/css-utilities#ionic-breakpoints)
    screens: {
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1200px',

      // minimalistic map: show only the map without the map toolbar & simplified map overlays
      miniMap: {
        // small width & landscape orientation
        raw: '(max-width: 576px) and (min-aspect-ratio: 1/1)',
      },
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: colors.black,
      white: colors.white,
      gray: colors.gray,
      red: colors.red,
      'diercke-blue': {
        DEFAULT: '#003E73', // Diercke CD Blau (dunkel)
        500: '#0065A6',
        400: '#0072B7', // Diercke CD Blau (hell)
        300: '#B9D8EB',
        250: '#DAEAF4', // Diercke CD Blau (hell) 15%
        200: '#D9E9F3', // Diercke CD Blau (dunkel) 10%
        150: '#E5F0F7', // Diercke CD Blau (hell) 10%
        100: '#F2F5F8', // Diercke CD Blau (dunkel) 5%
        50: '#F2F8FB', // Diercke CD Blau (hell) 5%
      },
      'diercke-foo': {
        DEFAULT: '#B8CEDE', // Lighter adaptions for Atlas
      },
      'diercke-bar': {
        DEFAULT: '#EFF1F9', // Lighter adaptions for Atlas
      },
      'diercke-red': {
        DEFAULT: '#E84624', // Diercke CD Rot
      },
    },
    fontSize: {
      xs: '0.75rem', // 12px | 0.625em = 10px
      sm: '0.875rem', // 14px | 0.75rem = 12px
      base: '1rem', // 16px | 0.9375rem = 15px
    },
    fontWeight: {
      light: '300',
      normal: '400',
      semibold: '700',
      bold: '900',
    },
    extend: {
      fontFamily: {
        sans: [
          'Lato',
          'ui-sans-serif',
          'system-ui',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          '"Noto Sans"',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
          '"Noto Color Emoji"',
        ],
        adaraSerif: [
          'AdaraSerif',
          'ui-serif',
          'Georgia',
          'Cambria',
          '"Times New Roman"',
          'Times',
          'serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
          '"Noto Color Emoji"',
        ],
        courierMono: [
          'CourierMono',
          'ui-monospace',
          'SFMono-Regular',
          'Menlo',
          'Monaco',
          'Consolas',
          '"Liberation Mono"',
          '"Courier New"',
          'monospace',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
          '"Noto Color Emoji"',
        ],
        jannisHand: [
          'JannisHand',
          'ui-cursive',
          // Windows:
          'Segoe Script',
          'Lucida Handwriting',
          'Comic Sans MS',
          // Mac/iOS:
          'Snell Roundhand',
          'Noteworthy',
          // Android:
          'Dancing Script',
          'cursive',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
          '"Noto Color Emoji"',
        ],
        karto: [
          'Karto',
          'ui-sans-serif',
          'system-ui',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          '"Noto Sans"',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
          '"Noto Color Emoji"',
        ],
        sirahSans: [
          'SirahSans',
          'ui-sans-serif',
          'system-ui',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          '"Noto Sans"',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
          '"Noto Color Emoji"',
        ],
      },
    },
  },
  plugins: [],
};

module.exports = config;
