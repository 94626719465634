import {useCesium} from 'resium';
import * as Cesium from 'cesium';
import {useIonRouter} from '@ionic/react';
import {Fragment, useCallback, useEffect, useRef, useState} from 'react';

import {isOneOf} from '../../utils/assert';
import {getBaseName} from '../../modules/url';

export const DIMENSION_VALUES = ['1', '2', '3', '4', '5', '6'] as const;
export type Dimension = (typeof DIMENSION_VALUES)[number];
export const isDimension = isOneOf(...DIMENSION_VALUES);

const scratch = new Cesium.Cartesian2();

type Params = {
  label: string;
  longitude: number;
  latitude: number;
  capital: boolean;
  dimension: Dimension;
};
const LabelEntity = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [params, setParams] = useState<Params | null>(null);
  const router = useIonRouter();
  const {viewer} = useCesium();

  useEffect(() => {
    const params = new URLSearchParams(router.routeInfo.search);
    const label = params.get('label');
    const longitude = Number(params.get('long'));
    const latitude = Number(params.get('lati'));
    const capital = params.get('capital') === String(true);
    const dimension = params.get('dimension');
    if (longitude && latitude && label && isDimension(dimension)) {
      setParams({
        label: label,
        longitude,
        latitude,
        capital,
        dimension,
      });
    }
  }, [router]);

  const updateOverlay = useCallback(() => {
    if (viewer && params && ref.current) {
      const position = Cesium.Cartesian3.fromDegrees(params.longitude, params.latitude);
      const canvasPosition = viewer.scene.cartesianToCanvasCoordinates(position, scratch);
      if (Cesium.defined(canvasPosition)) {
        ref.current.style.top = `${canvasPosition.y}px`;
        ref.current.style.left = `${canvasPosition.x}px`;
      }
    }
  }, [viewer, params]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.preRender.addEventListener(updateOverlay);
    return () => {
      viewer.scene.preRender.removeEventListener(updateOverlay);
    };
  }, [viewer, updateOverlay]);

  if (!params) return <></>;
  const T = params.capital ? 'u' : Fragment;
  return (
    <div className="absolute flex items-center select-none -translate-y-1/2 pointer-events-none" ref={ref}>
      <img className="h-6 -translate-x-1/2" src={getBaseName() + `assets/signatures/s${params.dimension}.svg`} alt="" />
      <div className="drop-shadow-outline-black text-[28px] text-white">
        <T>{params.label}</T>
      </div>
    </div>
  );
};

export default LabelEntity;
