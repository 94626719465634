import * as i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';

import {getBaseName} from './url';

i18next
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: ['de', 'en'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: getBaseName() + `assets/locales/{{lng}}/{{ns}}.json`,
    },
    detection: {
      order: ['localStorage'],
    },
    saveMissing: process.env.NODE_ENV === 'development',
    missingKeyHandler: (lng, ns, key) => console.info(`i18n: Missing translation: [${lng.join(', ')}] '${key}'`),
    ns: ['common', 'globus'], // specify additional namespaces here
    defaultNS: 'common',
  })
  .catch(console.error);
