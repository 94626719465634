import {PropsWithChildren, useMemo} from 'react';
import {useFocusRing, useId} from 'react-aria';

type Props<V extends string> = {
  label: string;
  name?: string;
  value: V;
  checked?: boolean;
  onChange?(value: V): void;
  defaultChecked?: boolean;
};

function RadioButton<V extends string>({
  label,
  name,
  value,
  checked,
  onChange,
  defaultChecked,
  children,
}: PropsWithChildren<Props<V>>): React.ReactElement {
  const id = useId();
  const {isFocusVisible, focusProps} = useFocusRing();

  const handleChange = useMemo(() => {
    if (onChange) {
      return (): void => onChange(value);
    }
  }, [onChange, value]);

  return (
    <label className="cursor-pointer" htmlFor={id} title={label}>
      <input
        className="sr-only"
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={handleChange}
        {...focusProps}
      />
      <div className={`hover:bg-diercke-blue-250 ${isFocusVisible ? 'ring' : ''}`}>{children}</div>
    </label>
  );
}

export default RadioButton;
