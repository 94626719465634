import {useEffect, useState} from 'react';
import * as Cesium from 'cesium';
import {useTranslation} from 'react-i18next';

import {useGlobus} from '../GlobusProvider';
import useThrottle from '../../../hooks/useThrottle';

function formatRad(rad: number): string {
  const deg = Cesium.Math.toDegrees(rad);
  const min = (deg - Math.floor(deg)) * 60;
  const sec = (min - Math.floor(min)) * 60;
  return `${deg.toFixed(0)}°${min.toFixed(0).padStart(2, '0')}′${sec.toFixed(0).padStart(2, '0')}″`;
}

function formatHeight(height: number): string {
  if (10_000_000 < height) {
    return `${(height / 1_000_000).toFixed(2)}Mm`;
  } else if (10_000 < height) {
    return `${(height / 1_000).toFixed(2)}km`;
  } else {
    return `${height.toFixed(2)}m`;
  }
}

type OverlayPositionProps = {
  imageName?: string;
};
const OverlayPosition: React.FC<OverlayPositionProps> = ({imageName}) => {
  const {t} = useTranslation();
  const {controller} = useGlobus();
  const [position, setPosition] = useState<null | Cesium.Cartographic>(null);
  useEffect(() => controller?.onCameraChange((cam) => setPosition(cam.positionCartographic)), [controller]);
  // TODO: remove when the monospace problem is fixed
  const trottPosition = useThrottle(position, 200);

  return (
    <div className="text-center">
      <div className="pointer-events-auto inline-block p-1 rounded-b bg-white/80">
        {imageName}
        {/* TODO: use monospace font? or fixed width per element */}
        {trottPosition !== null && (
          <div className="whitespace-pre">
            {/* TODO: use another delimiter, using " - " as a delimiter for negative numbers is misleading at best */}
            {formatRad(trottPosition.latitude)}
            {'N - '}
            {formatRad(trottPosition.longitude)}
            {'O  |  '}
            {t('globus:description.height')}
            {': '}
            {formatHeight(trottPosition.height)}
          </div>
        )}
      </div>
    </div>
  );
};

export default OverlayPosition;
