import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

// TODO: Support multi-range breakpoints? https://tailwindcss.com/docs/screens#multi-range-breakpoints
type BreakpointDefinition =
  | string
  | {min: string}
  | {max: string}
  | {min: string; max: string}
  | {raw: string};

type ScreenName = keyof typeof tailwindConfig.theme.screens;

const screens = (fullConfig.theme?.screens || {}) as Record<
  ScreenName,
  BreakpointDefinition
>;

export type Breakpoints = Record<ScreenName, boolean>;
export type BreakpointsListener = (breakpoints: Breakpoints) => void;

let listeners: BreakpointsListener[] = [];

const breakpoints: Record<string, boolean> = {};

function buildBreakpointMediaQuery(breakpoint: BreakpointDefinition) {
  let query;
  if (typeof breakpoint === 'string') {
    query = `(min-width: ${breakpoint})`;
  } else if ('min' in breakpoint && 'max' in breakpoint) {
    query = `(min-width: ${breakpoint.min}) and (max-width: ${breakpoint.max})`;
  } else if ('min' in breakpoint) {
    query = `(min-width: ${breakpoint.min}))`;
  } else if ('max' in breakpoint) {
    query = `(max-width: ${breakpoint.max})`;
  } else if ('raw' in breakpoint) {
    query = breakpoint.raw;
  }
  return query;
}

Object.entries(screens).forEach(([name, breakpoint]) => {
  const query = buildBreakpointMediaQuery(breakpoint);
  if (!query) {
    console.error('unsupported breakpoint', breakpoint);
    return;
  }

  const mQ = window.matchMedia(query);
  mQ.addEventListener('change', () => {
    breakpoints[name] = mQ.matches;
    listeners.forEach((listener) => {
      listener(getBreakpoints());
    });
  });

  breakpoints[name] = mQ.matches;
});

export const getBreakpoints = (): Breakpoints => ({...breakpoints} as Breakpoints);
export const observeBreakpoints = (listener: BreakpointsListener) => {
  listeners.push(listener);
  return () => {
    listeners = listeners.filter((l) => l !== listener);
  };
};

export type FontStack = keyof typeof fullConfig.theme.extend.fontFamily;
// @ts-ignore
const fontStacks = (fullConfig.theme?.fontFamily || {}) as Record<FontStack, string[]>;

export function getFontStack(name: FontStack) {
  return fontStacks[name];
}
