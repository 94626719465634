import {useTranslation} from 'react-i18next';
import Modal, {Props as ReactModalProps} from 'react-modal';
import {IonAccordion, IonAccordionGroup, IonItem, IonLabel} from '@ionic/react';

import {useLicensesData, useUserData} from '../api/data';

import Icon from './shared/Icon';

import './SettingsModel.css';
import Dialog from "./shared/Dialog";

type Props = Pick<ReactModalProps, 'isOpen' | 'onRequestClose'>;

const SettingsModal: React.FC<Props> = ({isOpen, onRequestClose}) => {
  const user = useUserData();
  const licensed = user?.licenses || [];
  const licensesState = useLicensesData();
  const userLicense = licensesState?.map(l => l.web_nr) || [];

  const {i18n, t} = useTranslation();
  const changeLanguage = (lng: string) => i18n.changeLanguage(lng).catch(console.error);

  const dierckeUserLicenses = licensed.reduce(
    (acc, cur) => (userLicense.includes(cur.id) ? {...acc, [cur.id]: cur.title} : acc),
    {}
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="fixed inset-0 bg-black/50 grid place-items-center"
      className="w-[80vw] max-w-[420px] h-[80vh] max-h-[700px] bg-diercke-blue-50 flex flex-col rounded-md"
    >
      <Dialog header={t('settings.head')} onClose={onRequestClose}>
      <div className="p-3 pl-5 bg-white flex flex-col">
        <h1>
          {t('settings.name')}: {user ? user.account : t('settings.guest').toUpperCase()}
        </h1>
        <h1>
          {t('settings.licenseTyp')}: <b>{user ? user.clientGroup : 'DEMO'}</b>
        </h1>
        <a className="underline" href={'https://diercke.westermann.de/diercke-globus-datenschutz'}>
          Datenschutzhinweise
        </a>
      </div>
      <div className="h-full flex flex-col justify-start overflow-y-scroll">
        <IonAccordionGroup>
          <IonAccordion value="first">
            <IonItem slot="header" color="diercke-blue ">
              <IonLabel>{t('settings.language')}</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              <div className="h-full flex flex-col bg-white px-4 py-2 gap-1">
                {[...i18n.languages].sort().map((lang) => {
                  const fixedT = i18n.getFixedT(lang);
                  const langFull = fixedT('language');
                  return (
                    <div
                      key={lang}
                      className={
                        (i18n.language === lang ? 'bg-diercke-blue-250 ' : '') + 'px-3 cursor-pointer rounded w-fit'
                      }
                      onClick={() => changeLanguage(lang)}
                      title={`${fixedT('settings.changeLanguage')} ${langFull}`}
                    >
                      {langFull}
                    </div>
                  );
                })}
              </div>
            </div>
          </IonAccordion>
          <IonAccordion value="second">
            <IonItem slot="header" color="diercke-blue ">
              <IonLabel>{t('settings.licenses')}</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              <ul className="h-full flex flex-col overflow-y-auto divide-y-2 bg-white px-2">
                {Object.keys(dierckeUserLicenses).length === 0 ? (
                  <div className="py-2">{t('settings.noLicenseAvailable')}</div>
                ) : (
                  Object.entries(dierckeUserLicenses).map(([id, title]) => (
                    <li key={id} className="flex flex-col text-sm w-full p-2">
                      <span>{`${t('settings.licenseName')}: ${title}`}</span>
                      <span>{`${t('settings.licenseKey')}: ${id}`}</span>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </IonAccordion>
          <IonAccordion value="third">
            <IonItem slot="header" color="diercke-blue ">
              <IonLabel>{t('settings.legalNotice')}</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              <ul className="h-full flex flex-col overflow-y-auto divide-y-2 bg-white p-2.5">
                <p className="font-karto">
                  Copyright 2023 Westermann
                  <br />
                  Kartenmaterial: Westermann‑Kartographie
                  <br />
                  Produktentwicklung: KST innovations GmbH
                  <br />
                  <br />
                  Diercke Atlas:
                  <br />
                  Projektleitung: Dr. Erik Braune
                  <br />
                  Programmierung Westermann: Stefan Hochmuth
                  <br />
                  Kartographische Umsetzung:
                  <br />
                  Manuela Franke, Florian Kronfeldt
                  <br />
                  Kartographische Bearbeitung:
                  <br /> Christian Haack, Harald Iden, Michael Perkovic,
                  <br /> Arndt Russ, Henrik Selle, Heiko Strothmann,
                  <br />
                  Sascha Vahlberg, Lars Vonau
                  <br />
                  <br />
                  Diercke Weltatlas (2023):
                  <br />
                  Leitung: Thomas Michael
                  <br />
                  Redaktion: Thomas Michael, Irene Reitmeier,
                  <br /> Björn Richter, Reinhold Schlimm, Jeremy Wölbling
                  <br />
                  Multimedia‑Redaktion:
                  <br /> Dr. Erik Braune, Josephine Strübing
                  <br />
                  Herstellung: Michael Albrecht, Westermann‑Kartographie Umschlaggestaltung: Studio Grau, Berlin
                  <br />
                  Layout und Satz: Westermann‑Kartographie
                  <br />
                  <br />
                  Diercke Weltatlas (2015):
                  <br />
                  Leitung: Thomas Michael
                  <br />
                  Redaktion: Christian Domdey, Irene Reitmeier,
                  <br /> Björn Richter, Reinhold Schlimm
                  <br />
                  Multimedia-Redaktion:
                  <br /> Dr. Erik Braune, Heiko Scholz
                  <br />
                  Kartographische Umsetzung: Michael Albrecht
                  <br />
                  Titelgestaltung: Jennifer Kirchhof, Thomas Schröder
                  <br />
                  <br />
                  Diercke Weltatlas 2 – Gesamtausgabe:
                  <br />
                  Leitung: Thomas Michael
                  <br />
                  Redaktion: Christian Domdey, Irene Reitmeier,
                  <br /> Björn Richter, Reinhold Schlimm
                  <br />
                  Multimedia-Redaktion:
                  <br /> Dr. Erik Braune, Heiko Scholz
                  <br />
                  Kartographische Umsetzung: Michael Albrecht
                  <br />
                  Titelgestaltung: boje5 Grafik & Werbung, Eckard Schönke, Braunschweig
                  <br />
                  <br />
                  Diercke Weltatlas 2 – Bayern:
                  <br />
                  Leitung: Thomas Michael
                  <br />
                  Redaktion: Christian Domdey, Irene Reitmeier,
                  <br /> Björn Richter, Reinhold Schlimm
                  <br />
                  Multimedia-Redaktion:
                  <br /> Dr. Erik Braune, Heiko Scholz
                  <br />
                  Kartographische Umsetzung: Michael Albrecht
                  <br />
                  Titelgestaltung: boje5 Grafik & Werbung, Eckard Schönke, Braunschweig
                  <br />
                  <br />
                  Diercke Weltatlas 2 – Baden-Württemberg:
                  <br />
                  Leitung: Thomas Michael
                  <br />
                  Redaktion: Christian Domdey, Irene Reitmeier,
                  <br /> Björn Richter, Reinhold Schlimm
                  <br />
                  Multimedia-Redaktion:
                  <br /> Dr. Erik Braune, Heiko Scholz
                  <br />
                  Kartographische Umsetzung: Michael Albrecht
                  <br />
                  Titelgestaltung: boje5 Grafik & Werbung, Eckard Schönke, Braunschweig
                  <br />
                  <br />
                  Diercke Weltatlas 2 – Nordrhein-Westfalen:
                  <br />
                  Leitung: Thomas Michael
                  <br />
                  Redaktion: Christian Domdey, Irene Reitmeier,
                  <br /> Björn Richter, Reinhold Schlimm
                  <br />
                  Multimedia-Redaktion:
                  <br />
                  Dr. Erik Braune, Heiko Scholz
                  <br />
                  Kartographische Umsetzung: Michael Albrecht
                  <br />
                  Titelgestaltung: boje5 Grafik & Werbung, Eckard Schönke, Braunschweig
                  <br />
                  <br />
                  Diercke Weltatlas 2 – Rheinland-Pfalz:
                  <br />
                  Leitung: Thomas Michael
                  <br />
                  Redaktion: Christian Domdey, Irene Reitmeier,
                  <br /> Björn Richter, Reinhold Schlimm
                  <br />
                  Multimedia-Redaktion:
                  <br />
                  Dr. Erik Braune, Heiko Scholz
                  <br />
                  Kartographische Umsetzung: Michael Albrecht
                  <br />
                  Titelgestaltung: boje5 Grafik & Werbung, Eckard Schönke, Braunschweig
                  <br />
                  <br />
                  Diercke Drei Universalatlas:
                  <br />
                  Leitung: Thomas Michael
                  <br />
                  Redaktion: Thomas Michael, Irene Reitmeier,
                  <br /> Björn Richter, Reinhold Schlimm
                  <br />
                  Herstellung:
                  <br /> Michael Albrecht, Westermann-Kartographie
                  <br />
                  Umschlaggestaltung:
                  <br />
                  boje5 Grafik & Werbung, Eckard Schönke, Braunschweig
                  <br />
                  Layout und Satz: Westermann-Kartographie
                  <br />
                  <br />
                  Diercke International Atlas:
                  <br />
                  Chief Executive: Thomas Michael
                  <br />
                  Editorial: Irene Reitmeier,
                  <br /> Björn Richter, Reinhold Schlimm
                  <br />
                  Production: Michael Albrecht, Westermann Kartographie
                  <br />
                  Front cover design:
                  <br />
                  boje5 Grafik & Werbung, Eckard Schönke, Braunschweig
                  <br />
                  Layout and typesetting: Westermann Kartographie
                </p>
              </ul>
            </div>
          </IonAccordion>
        </IonAccordionGroup>
      </div>
      </Dialog>
    </Modal>
  );
};

export default SettingsModal;
