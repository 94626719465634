import {useTranslation} from 'react-i18next';

import {isOneOf} from '../utils/assert';

export type Language = 'en' | 'de';

export default function useLanguage(): Language {
  const lang = useTranslation().i18n.language;
  return isOneOf('en', 'de')(lang) ? lang : 'en';
}
