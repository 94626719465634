import {Redirect, Route, RouteComponentProps, Switch} from 'react-router';

import {useKmzGeoFrame} from '../api/data';
import '../theme/base.css';

import {default as GlobusPageBase} from './pages/GlobusPage';
import LinksPage from './pages/LinksPage';
import TopBar from './top-bar/TopBar';
import {isGlobusImage} from './globus/GlobusProvider';

const GlobusPage: React.FC<RouteComponentProps> = ({location}) => {
  const params = new URLSearchParams(location.search);

  const imgStr = params.get('img');
  const img = isGlobusImage(imgStr) ? imgStr : null;

  const latiStr = params.get('lati');
  const lati = latiStr !== null ? parseFloat(latiStr) : undefined;
  const longStr = params.get('long');
  const long = longStr !== null ? parseFloat(longStr) : undefined;
  const heightStr = params.get('height');
  const height = heightStr !== null ? parseFloat(heightStr) : undefined;
  const position =
    lati !== undefined &&
    !Number.isNaN(lati) &&
    long !== undefined &&
    !Number.isNaN(height) &&
    height !== undefined &&
    !Number.isNaN(long)
      ? {lati, long, height}
      : null;

  const kmzIdStr = params.get('kmz');
  const kmzId = kmzIdStr !== null ? parseInt(kmzIdStr, 10) : null;
  const {data: kmzGeoFrame} = useKmzGeoFrame(kmzId);

  return (
    <GlobusPageBase
      kmzId={kmzId}
      img={img}
      position={position}
      boundingBox={kmzGeoFrame !== undefined ? kmzGeoFrame.box : null}
    />
  );
};

const Site: React.FC = () => (
  <div className="w-full h-full flex flex-col">
    <div className="flex-grow-0 flex-shrink-0">
      <TopBar />
    </div>

    <div className="flex-grow flex-shrink min-h-0">
      <Switch>
        <Route path="/Apps/diercke.html" exact render={props => <Redirect push={false} to={{
          pathname: "/globus",
          search: props.location.search,
          state: props.location.state,
        }}/>}/>
        <Route path="/globus" exact component={GlobusPage} />
        <Route>Page Not Found</Route>
      </Switch>
    </div>
  </div>
);

export default Site;
