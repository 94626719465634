import {useCallback} from 'react';
import {useTranslation} from "react-i18next";

import {IconName} from '../../shared/Icon';
import {GlobusDefaultMovementMode, useGlobus} from '../GlobusProvider';

import OverlayButton from './OverlayButton';

// TODO
export const ICON_BY_DEFAULT_MOVEMENT_MODE: Record<GlobusDefaultMovementMode, IconName> = {
  zoom: 'Verschieben',
  rotate: 'Bewegen',
  tilt: 'Refresh',
  look: '3D',
};

type Props = {
  mode: GlobusDefaultMovementMode;
};

const DefaultMovementButton: React.FC<Props> = ({
  mode,

  // we need to pass some hidden props to the button
  ...rest
}) => {
  const {setGlobus} = useGlobus();
  const {t} = useTranslation();

  const handleClick = useCallback(
    () =>
      setGlobus((draft) => {
        draft.defaultMovementMode = mode;
      }),
    [setGlobus, mode]
  );

  return <OverlayButton onClick={handleClick} icon={ICON_BY_DEFAULT_MOVEMENT_MODE[mode]} title={t(`globus:move.${mode}`)} {...rest}/>;
};

export default DefaultMovementButton;
