import {forwardRef} from 'react';

import Button, {ButtonProps} from '../shared/Button';

const TopBarButton: React.FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
  ({className = '', ...rest}, ref) => {
    return <Button iconClassName="text-white" className={`m-1 ${className}`} {...rest} buttonRef={ref} />;
  }
);

export default TopBarButton;
