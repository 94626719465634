import {GLOBUS_IMAGE_VALUES} from './GlobusProvider';
import {TileImageryProvider} from './TileImageryProvider';

const TileImageryProviders = () => {
  return (
    <>
      {GLOBUS_IMAGE_VALUES.map((image) => (
        <TileImageryProvider key={image} image={image} />
      ))}
    </>
  );
};

export default TileImageryProviders;
