import {useLayoutEffect} from 'react';
import {useCesium} from 'resium';

export const RemoveCesiumLogo: React.FC = () => {
  const {viewer} = useCesium();
  useLayoutEffect(() => {
    if (viewer) {
      // https://community.cesium.com/t/cesium-ion-logo-removal/8979
      viewer.cesiumWidget.creditContainer.parentNode?.removeChild(viewer.cesiumWidget.creditContainer);
    }
  });
  return null;
};
