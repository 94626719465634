import {useEffect, useState} from 'react';
import * as Cesium from 'cesium';
import {CameraFlyTo, useCesium} from 'resium';

import {Vec4} from '../../utils/vec4';

export type UpdatePositionProps = {
  position: null | {
    /** Latitude in degree (-90.0..90.0) */
    lati: number;
    /** Longitude in degree (-180.0..180.0) */
    long: number;
    /** Height in meter */
    height: number;
  };
  boundingBox: null | Vec4;
};

const CameraFlyToCoords: React.FC<UpdatePositionProps> = ({position, boundingBox}) => {
  const {viewer} = useCesium();
  const [destination, setDestination] = useState<Cesium.Cartesian3 | Cesium.Rectangle | null>(null);

  useEffect(() => {
    if (!viewer) return;
    if (position) {
      setDestination(Cesium.Cartesian3.fromDegrees(position.long, position.lati, position.height));
    } else if (boundingBox) {
      const [west, north, east, south] = boundingBox;
      setDestination(Cesium.Rectangle.fromDegrees(west, south, east, north));
    }
  }, [viewer, position, boundingBox]);
  if (!destination) return null;
  return <CameraFlyTo destination={destination} />;
};

export default CameraFlyToCoords;
