import Button, {ButtonProps} from '../../shared/Button';

export const bgClasses = '';

export type OverlayButtonProps<T extends keyof HTMLElementTagNameMap & keyof JSX.IntrinsicElements = 'button'> =
  ButtonProps<T> & {
    orientation?: 'x' | 'y';
    grouped?: boolean;
  };

const OverlayButton = <T extends keyof HTMLElementTagNameMap & keyof JSX.IntrinsicElements = 'button'>({
  grouped = false,
  orientation = 'x',
  isPopoverOpen,
  className = '',
  ...rest
}: OverlayButtonProps<T>) => {
  let borderClasses: string;
  if (grouped) {
    if (orientation === 'x') {
      borderClasses = isPopoverOpen ? '' : 'last:rounded-r-lg first:rounded-l-lg';
    } else {
      borderClasses = isPopoverOpen ? '' : 'last:rounded-b-lg first:rounded-t-lg';
    }
  } else {
    if (orientation === 'x') {
      borderClasses = isPopoverOpen ? '' : 'rounded-lg';
    } else {
      borderClasses = isPopoverOpen ? '' : 'rounded-lg';
    }
  }

  return (
    <Button
      className={`
        p-0.5 pointer-events-auto bg-white/80
        ${borderClasses}
        ${grouped ? (orientation === 'x' ? 'last:pr-1 fist:pl-1' : 'last:pb-1 fist:pt-1') : ''}
        ${className}
       `}
      isPopoverOpen={isPopoverOpen}
      {...rest}
    />
  );
};

export default OverlayButton;
