import {isString} from 'lodash';
import {useQuery} from 'react-query';
import qs from 'qs';

import {getApiBase, getKmzUrl} from '../modules/url';
import {createIsArrayWithItems, ensureIs} from '../utils/assert';
import {fetchJson, sendRequest} from '../utils/httpRequestHelper';
import {isRecord} from '../utils/isRecord';
import {isArrayVec4, Vec4} from '../utils/vec4';

import type {ImageDataType, License, User} from './types';

const isArrayContainingStrings = createIsArrayWithItems(isString);

export function useUserData(): User | null {
  const {data} = useQuery(['user-data'], async () => {
    const {data, status, headers} = await sendRequest({
      url: getApiBase() + 'user/',
      headers: {
        accept: 'application/json',
      },
      validateStatus(status) {
        // allow 403 = Forbidden
        return (200 <= status && status <= 299) || status === 403;
      },
    });

    if (status === 403) {
      return null;
    }

    if (headers['content-type']?.startsWith('application/json') === false) {
      throw new Error(`http fetch error: got non-json content-type for user data`);
    }

    return data.data ? (data.data as User) : null;
  });
  return data ?? null;
}

export function useLicensesData(): License[] | null {
  const user = useUserData();

  const {data} = useQuery({
    queryKey: ['user-licenses'],
    async queryFn() {
      const {data} = await fetchJson({
        url: getApiBase() + 'database/licenses',
      });
      return data ? (data as License[]) : null;
    },
    enabled: user !== null,
  });
  return data ?? null;
}

export function useImageData(kmzId: number | null) {
  const queryKey = 'image-data';
  return useQuery(
    [queryKey, kmzId],
    async (): Promise<ImageDataType> => {
      const {data} = await fetchJson({
        url: `${getApiBase()}database/${queryKey}?${qs.stringify({params: [kmzId]})}`,
      });
      return data[0];
    },
    {enabled: kmzId !== null}
  );
}

export function useKmzGeoFrame(kmzId: number | null) {
  return useQuery(
    ['kmz-geo-frame', kmzId],
    async () => {
      const result = await fetchJson({
        url: `${getApiBase()}db/geoframe/${kmzId}`,
      });
      const data = ensureIs(result.data, isRecord);
      if (!isArrayContainingStrings(data.box) || !isArrayVec4(data.box)) {
        throw new Error('The geo frame box has an unexpected type');
      }
      return {box: data.box.map(parseFloat) as Vec4};
    },
    {enabled: kmzId !== null}
  );
}

export function useIsKmzAvailable(kmzId: number | null) {
  return useQuery(
    ['kmz', kmzId],
    async () => {
      if (kmzId === null) {
        return false;
      }

      const {status} = await sendRequest({
        url: getKmzUrl(kmzId),
        validateStatus: (status) => (200 <= status && status <= 299) || status === 403,
      });

      return 200 <= status && status <= 299;
    },
    {enabled: kmzId !== null}
  );
}
