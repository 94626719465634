import type {FunctionComponent, SVGProps} from 'react';

import {ReactComponent as SvgDierckeCdDierckeLogoBlau} from './DIERCKE-CD/DIERCKE_Logo_Blau.svg';
import {ReactComponent as SvgDierckeCdDierckeLogoWeiss} from './DIERCKE-CD/DIERCKE_Logo_Weiss.svg';
import {ReactComponent as SvgDierckeCdDierckeWeltatlasSchmetterlingNetzWeiss} from './DIERCKE-CD/DIERCKE_Weltatlas_Schmetterling_Netz_Weiss.svg';
import {ReactComponent as SvgDierckeCdLabelWestermann01A} from './DIERCKE-CD/Label-Westermann-01a.svg';
import {ReactComponent as SvgDierckeCdLabelWestermann01B} from './DIERCKE-CD/Label-Westermann-01b.svg';
import {ReactComponent as SvgIcn40Px3DGlobus} from './Icn/40px/3D-Globus.svg';
import {ReactComponent as SvgIcn40Px3D} from './Icn/40px/3D.svg';
import {ReactComponent as SvgIcn40Px3Doff} from './Icn/40px/3Doff.svg';
import {ReactComponent as SvgIcn40PxAlleSzenen} from './Icn/40px/Alle_Szenen.svg';
import {ReactComponent as SvgIcn40PxAnfasser} from './Icn/40px/Anfasser.svg';
import {ReactComponent as SvgIcn40PxAuge} from './Icn/40px/Auge.svg';
import {ReactComponent as SvgIcn40PxAusrichtenBlock} from './Icn/40px/Ausrichten_Block.svg';
import {ReactComponent as SvgIcn40PxAusrichtenL} from './Icn/40px/Ausrichten_L.svg';
import {ReactComponent as SvgIcn40PxAusrichtenM} from './Icn/40px/Ausrichten_M.svg';
import {ReactComponent as SvgIcn40PxAusrichtenR} from './Icn/40px/Ausrichten_R.svg';
import {ReactComponent as SvgIcn40PxBenachrichtigung} from './Icn/40px/Benachrichtigung.svg';
import {ReactComponent as SvgIcn40PxBewegen} from './Icn/40px/Bewegen.svg';
import {ReactComponent as SvgIcn40PxBrowser} from './Icn/40px/Browser.svg';
import {ReactComponent as SvgIcn40PxCamRahmen} from './Icn/40px/Cam_Rahmen.svg';
import {ReactComponent as SvgIcn40PxCam} from './Icn/40px/Cam.svg';
import {ReactComponent as SvgIcn40PxCheckbox01} from './Icn/40px/Checkbox-01.svg';
import {ReactComponent as SvgIcn40PxCheckbox02} from './Icn/40px/Checkbox-02.svg';
import {ReactComponent as SvgIcn40PxCheckhaken} from './Icn/40px/Checkhaken.svg';
import {ReactComponent as SvgIcn40PxChoach} from './Icn/40px/Choach.svg';
import {ReactComponent as SvgIcn40PxDierckeStar} from './Icn/40px/Diercke_Star.svg';
import {ReactComponent as SvgIcn40PxDokument} from './Icn/40px/Dokument.svg';
import {ReactComponent as SvgIcn40PxDoppelseite} from './Icn/40px/Doppelseite.svg';
import {ReactComponent as SvgIcn40PxDownload} from './Icn/40px/Download.svg';
import {ReactComponent as SvgIcn40PxDrehenCopy} from './Icn/40px/Drehen Copy.svg';
import {ReactComponent as SvgIcn40PxDrehenAuto} from './Icn/40px/Drehen_Auto.svg';
import {ReactComponent as SvgIcn40PxDrehen} from './Icn/40px/Drehen.svg';
import {ReactComponent as SvgIcn40PxEbenen} from './Icn/40px/Ebenen.svg';
import {ReactComponent as SvgIcn40PxEinstellungen} from './Icn/40px/Einstellungen.svg';
import {ReactComponent as SvgIcn40PxFarbe01} from './Icn/40px/Farbe-01.svg';
import {ReactComponent as SvgIcn40PxFarbe02} from './Icn/40px/Farbe-02.svg';
import {ReactComponent as SvgIcn40PxFarbe03} from './Icn/40px/Farbe-03.svg';
import {ReactComponent as SvgIcn40PxFensterHorizontal} from './Icn/40px/Fenster-Horizontal.svg';
import {ReactComponent as SvgIcn40PxFensterSperren} from './Icn/40px/Fenster-Sperren.svg';
import {ReactComponent as SvgIcn40PxFensterUnterteilen} from './Icn/40px/Fenster-Unterteilen.svg';
import {ReactComponent as SvgIcn40PxFensterVertikal} from './Icn/40px/Fenster-Vertikal.svg';
import {ReactComponent as SvgIcn40PxFilter} from './Icn/40px/Filter.svg';
import {ReactComponent as SvgIcn40PxFontgroesse} from './Icn/40px/Fontgroesse.svg';
import {ReactComponent as SvgIcn40PxFontstil} from './Icn/40px/Fontstil.svg';
import {ReactComponent as SvgIcn40PxForm01} from './Icn/40px/Form-01.svg';
import {ReactComponent as SvgIcn40PxForm02} from './Icn/40px/Form-02.svg';
import {ReactComponent as SvgIcn40PxForm03} from './Icn/40px/Form-03.svg';
import {ReactComponent as SvgIcn40PxForm04} from './Icn/40px/Form-04.svg';
import {ReactComponent as SvgIcn40PxForm05} from './Icn/40px/Form-05.svg';
import {ReactComponent as SvgIcn40PxGloben} from './Icn/40px/Globen.svg';
import {ReactComponent as SvgIcn40PxGrafik} from './Icn/40px/Grafik.svg';
import {ReactComponent as SvgIcn40PxGroesseKreis01} from './Icn/40px/Groesse-Kreis-01.svg';
import {ReactComponent as SvgIcn40PxGroesseKreis02} from './Icn/40px/Groesse-Kreis-02.svg';
import {ReactComponent as SvgIcn40PxGroesseKreis03} from './Icn/40px/Groesse-Kreis-03.svg';
import {ReactComponent as SvgIcn40PxGroesseKreis04} from './Icn/40px/Groesse-Kreis-04.svg';
import {ReactComponent as SvgIcn40PxGroesseViereck01} from './Icn/40px/Groesse-Viereck-01.svg';
import {ReactComponent as SvgIcn40PxGroesseViereck02} from './Icn/40px/Groesse-Viereck-02.svg';
import {ReactComponent as SvgIcn40PxGroesseViereck03} from './Icn/40px/Groesse-Viereck-03.svg';
import {ReactComponent as SvgIcn40PxGroesse} from './Icn/40px/Groesse.svg';
import {ReactComponent as SvgIcn40PxGruppe} from './Icn/40px/Gruppe.svg';
import {ReactComponent as SvgIcn40PxHandClick} from './Icn/40px/Hand_Click.svg';
import {ReactComponent as SvgIcn40PxHandSwipe} from './Icn/40px/Hand_Swipe.svg';
import {ReactComponent as SvgIcn40PxHilfe} from './Icn/40px/Hilfe.svg';
import {ReactComponent as SvgIcn40PxInfo} from './Icn/40px/Info.svg';
import {ReactComponent as SvgIcn40PxKarteWechseln} from './Icn/40px/Karte-Wechseln.svg';
import {ReactComponent as SvgIcn40PxKarte} from './Icn/40px/Karte.svg';
import {ReactComponent as SvgIcn40PxKreisToolAktiv} from './Icn/40px/Kreis-Tool-Aktiv.svg';
import {ReactComponent as SvgIcn40PxLegende} from './Icn/40px/Legende.svg';
import {ReactComponent as SvgIcn40PxLegendeBahnhof} from './Icn/40px/Legende/Bahnhof.svg';
import {ReactComponent as SvgIcn40PxLegendeBauwerk} from './Icn/40px/Legende/Bauwerk.svg';
import {ReactComponent as SvgIcn40PxLegendeBerg} from './Icn/40px/Legende/Berg.svg';
import {ReactComponent as SvgIcn40PxLegendeBergbau} from './Icn/40px/Legende/Bergbau.svg';
import {ReactComponent as SvgIcn40PxLegendeDienstleistungen} from './Icn/40px/Legende/Dienstleistungen.svg';
import {ReactComponent as SvgIcn40PxLegendeEinrichtung} from './Icn/40px/Legende/Einrichtung.svg';
import {ReactComponent as SvgIcn40PxLegendeFluesse} from './Icn/40px/Legende/Fluesse.svg';
import {ReactComponent as SvgIcn40PxLegendeForm01} from './Icn/40px/Legende/Form-01.svg';
import {ReactComponent as SvgIcn40PxLegendeForm02} from './Icn/40px/Legende/Form-02.svg';
import {ReactComponent as SvgIcn40PxLegendeForm03} from './Icn/40px/Legende/Form-03.svg';
import {ReactComponent as SvgIcn40PxLegendeGewaesser} from './Icn/40px/Legende/Gewaesser.svg';
import {ReactComponent as SvgIcn40PxLegendeIndustrie} from './Icn/40px/Legende/Industrie.svg';
import {ReactComponent as SvgIcn40PxLegendeKeramik} from './Icn/40px/Legende/Keramik.svg';
import {ReactComponent as SvgIcn40PxLegendeLandschaft} from './Icn/40px/Legende/Landschaft.svg';
import {ReactComponent as SvgIcn40PxLegendeLandwirtschaft} from './Icn/40px/Legende/Landwirtschaft.svg';
import {ReactComponent as SvgIcn40PxLegendeOrt} from './Icn/40px/Legende/Ort.svg';
import {ReactComponent as SvgIcn40PxLegendeSachwort} from './Icn/40px/Legende/Sachwort.svg';
import {ReactComponent as SvgIcn40PxLegendeStaat} from './Icn/40px/Legende/Staat.svg';
import {ReactComponent as SvgIcn40PxLegendeTitel} from './Icn/40px/Legende/Titel.svg';
import {ReactComponent as SvgIcn40PxLegendeTourismus} from './Icn/40px/Legende/Tourismus.svg';
import {ReactComponent as SvgIcn40PxLegendeVerkehr} from './Icn/40px/Legende/Verkehr.svg';
import {ReactComponent as SvgIcn40PxLegendeVolk} from './Icn/40px/Legende/Volk.svg';
import {ReactComponent as SvgIcn40PxLegendeWasser} from './Icn/40px/Legende/Wasser.svg';
import {ReactComponent as SvgIcn40PxLineal} from './Icn/40px/Lineal.svg';
import {ReactComponent as SvgIcn40PxLinie01} from './Icn/40px/Linie-01.svg';
import {ReactComponent as SvgIcn40PxLinie02} from './Icn/40px/Linie-02.svg';
import {ReactComponent as SvgIcn40PxLinie03} from './Icn/40px/Linie-03.svg';
import {ReactComponent as SvgIcn40PxLinienfette01} from './Icn/40px/Linienfette-01.svg';
import {ReactComponent as SvgIcn40PxLinienfette02} from './Icn/40px/Linienfette-02.svg';
import {ReactComponent as SvgIcn40PxLinienfette03} from './Icn/40px/Linienfette-03.svg';
import {ReactComponent as SvgIcn40PxLinienfette04} from './Icn/40px/Linienfette-04.svg';
import {ReactComponent as SvgIcn40PxListe} from './Icn/40px/Liste.svg';
import {ReactComponent as SvgIcn40PxLizenzDemo} from './Icn/40px/Lizenz-Demo.svg';
import {ReactComponent as SvgIcn40PxLizenzKaufen} from './Icn/40px/Lizenz-Kaufen.svg';
import {ReactComponent as SvgIcn40PxLizenz} from './Icn/40px/Lizenz.svg';
import {ReactComponent as SvgIcn40PxLoading} from './Icn/40px/Loading.svg';
import {ReactComponent as SvgIcn40PxMeeresspiegelMinus} from './Icn/40px/Meeresspiegel_Minus.svg';
import {ReactComponent as SvgIcn40PxMeeresspiegelPlus} from './Icn/40px/Meeresspiegel_Plus.svg';
import {ReactComponent as SvgIcn40PxMeeresspiegel} from './Icn/40px/Meeresspiegel.svg';
import {ReactComponent as SvgIcn40PxMenue} from './Icn/40px/Menue.svg';
import {ReactComponent as SvgIcn40PxMinus} from './Icn/40px/Minus.svg';
import {ReactComponent as SvgIcn40PxMuelltonne} from './Icn/40px/Muelltonne.svg';
import {ReactComponent as SvgIcn40PxMultipanel} from './Icn/40px/Multipanel.svg';
import {ReactComponent as SvgIcn40PxNavigator} from './Icn/40px/Navigator.svg';
import {ReactComponent as SvgIcn40PxNeigen} from './Icn/40px/Neigen.svg';
import {ReactComponent as SvgIcn40PxNeigungGrade} from './Icn/40px/Neigung_Grade.svg';
import {ReactComponent as SvgIcn40PxNord} from './Icn/40px/Nord.svg';
import {ReactComponent as SvgIcn40PxNotizToolAktiv} from './Icn/40px/Notiz-Tool-Aktiv.svg';
import {ReactComponent as SvgIcn40PxNutzerAngemeldet} from './Icn/40px/Nutzer-Angemeldet.svg';
import {ReactComponent as SvgIcn40PxNutzer} from './Icn/40px/Nutzer.svg';
import {ReactComponent as SvgIcn40PxOrdner} from './Icn/40px/Ordner.svg';
import {ReactComponent as SvgIcn40PxOverlayKarte} from './Icn/40px/Overlay-Karte.svg';
import {ReactComponent as SvgIcn40PxPfeilDropdownHoch02} from './Icn/40px/Pfeil-Dropdown-hoch-02.svg';
import {ReactComponent as SvgIcn40PxPfeilDropdownHoch} from './Icn/40px/Pfeil-Dropdown-hoch.svg';
import {ReactComponent as SvgIcn40PxPfeilDropdownRechts} from './Icn/40px/Pfeil-Dropdown-rechts.svg';
import {ReactComponent as SvgIcn40PxPfeilDropdownRunter02} from './Icn/40px/Pfeil-Dropdown-runter-02.svg';
import {ReactComponent as SvgIcn40PxPfeilDropdownRunter} from './Icn/40px/Pfeil-Dropdown-runter.svg';
import {ReactComponent as SvgIcn40PxPfeilHoch} from './Icn/40px/Pfeil-hoch.svg';
import {ReactComponent as SvgIcn40PxPfeilL} from './Icn/40px/Pfeil-L.svg';
import {ReactComponent as SvgIcn40PxPfeilR} from './Icn/40px/Pfeil-R.svg';
import {ReactComponent as SvgIcn40PxPfeilRunter} from './Icn/40px/Pfeil-runter.svg';
import {ReactComponent as SvgIcn40PxPfeilVor} from './Icn/40px/Pfeil-Vor.svg';
import {ReactComponent as SvgIcn40PxPfeilZurueck} from './Icn/40px/Pfeil-Zurueck.svg';
import {ReactComponent as SvgIcn40PxPlaylistEmpty} from './Icn/40px/Playlist_Empty.svg';
import {ReactComponent as SvgIcn40PxPlaylist} from './Icn/40px/Playlist.svg';
import {ReactComponent as SvgIcn40PxPlaylists} from './Icn/40px/Playlists.svg';
import {ReactComponent as SvgIcn40PxPlus} from './Icn/40px/Plus.svg';
import {ReactComponent as SvgIcn40PxPoiTextColor} from './Icn/40px/POI-Text-Color.svg';
import {ReactComponent as SvgIcn40PxPoiText} from './Icn/40px/POI-Text.svg';
import {ReactComponent as SvgIcn40PxPoi} from './Icn/40px/POI.svg';
import {ReactComponent as SvgIcn40PxRadiobutton01} from './Icn/40px/Radiobutton-01.svg';
import {ReactComponent as SvgIcn40PxRadiobutton02} from './Icn/40px/Radiobutton-02.svg';
import {ReactComponent as SvgIcn40PxRefresh} from './Icn/40px/Refresh.svg';
import {ReactComponent as SvgIcn40PxReset} from './Icn/40px/Reset.svg';
import {ReactComponent as SvgIcn40PxRotierenL} from './Icn/40px/Rotieren-L.svg';
import {ReactComponent as SvgIcn40PxRotierenR} from './Icn/40px/Rotieren-R.svg';
import {ReactComponent as SvgIcn40PxSchliesskreuz01} from './Icn/40px/Schliesskreuz-01.svg';
import {ReactComponent as SvgIcn40PxSchliesskreuz02} from './Icn/40px/Schliesskreuz-02.svg';
import {ReactComponent as SvgIcn40PxSchlossAktiv} from './Icn/40px/Schloss-Aktiv.svg';
import {ReactComponent as SvgIcn40PxSchloss} from './Icn/40px/Schloss.svg';
import {ReactComponent as SvgIcn40PxSchriftart} from './Icn/40px/Schriftart.svg';
import {ReactComponent as SvgIcn40PxSeite} from './Icn/40px/Seite.svg';
import {ReactComponent as SvgIcn40PxSpiel} from './Icn/40px/Spiel.svg';
import {ReactComponent as SvgIcn40PxSpracheDe} from './Icn/40px/Sprache-DE.svg';
import {ReactComponent as SvgIcn40PxSpracheUk} from './Icn/40px/Sprache-UK.svg';
import {ReactComponent as SvgIcn40PxStempelToolAktiv} from './Icn/40px/Stempel-Tool-Aktiv.svg';
import {ReactComponent as SvgIcn40PxStempel} from './Icn/40px/Stempel.svg';
import {ReactComponent as SvgIcn40PxStempelform} from './Icn/40px/Stempelform.svg';
import {ReactComponent as SvgIcn40PxStern02} from './Icn/40px/Stern-02.svg';
import {ReactComponent as SvgIcn40PxSternAktiv} from './Icn/40px/Stern-Aktiv.svg';
import {ReactComponent as SvgIcn40PxSternGruppe} from './Icn/40px/Stern-Gruppe.svg';
import {ReactComponent as SvgIcn40PxStern} from './Icn/40px/Stern.svg';
import {ReactComponent as SvgIcn40PxStiftAktiv} from './Icn/40px/Stift-Aktiv.svg';
import {ReactComponent as SvgIcn40PxStift} from './Icn/40px/Stift.svg';
import {ReactComponent as SvgIcn40PxSucheSchliessen} from './Icn/40px/Suche_Schliessen.svg';
import {ReactComponent as SvgIcn40PxSuche} from './Icn/40px/Suche.svg';
import {ReactComponent as SvgIcn40PxSynchronisierenAus} from './Icn/40px/Synchronisieren-Aus.svg';
import {ReactComponent as SvgIcn40PxSynchronisieren} from './Icn/40px/Synchronisieren.svg';
import {ReactComponent as SvgIcn40PxSzeneLaden} from './Icn/40px/Szene-Laden.svg';
import {ReactComponent as SvgIcn40PxSzeneSpeichern} from './Icn/40px/Szene-Speichern.svg';
import {ReactComponent as SvgIcn40PxSzenen} from './Icn/40px/Szenen.svg';
import {ReactComponent as SvgIcn40PxTextToolAktiv} from './Icn/40px/Text-Tool-Aktiv.svg';
import {ReactComponent as SvgIcn40PxTextZeigen} from './Icn/40px/Text-Zeigen.svg';
import {ReactComponent as SvgIcn40PxText} from './Icn/40px/Text.svg';
import {ReactComponent as SvgIcn40PxUeberblick} from './Icn/40px/Ueberblick.svg';
import {ReactComponent as SvgIcn40PxUeberhoehungMinus} from './Icn/40px/Ueberhoehung_Minus.svg';
import {ReactComponent as SvgIcn40PxUeberhoehungOff} from './Icn/40px/Ueberhoehung_OFF.svg';
import {ReactComponent as SvgIcn40PxUeberhoehungOn} from './Icn/40px/Ueberhoehung_ON.svg';
import {ReactComponent as SvgIcn40PxUeberhoehungPlus} from './Icn/40px/Ueberhoehung_Plus.svg';
import {ReactComponent as SvgIcn40PxVerlauf} from './Icn/40px/Verlauf.svg';
import {ReactComponent as SvgIcn40PxVerschieben} from './Icn/40px/Verschieben.svg';
import {ReactComponent as SvgIcn40PxVollbildAus} from './Icn/40px/Vollbild-Aus.svg';
import {ReactComponent as SvgIcn40PxVollbildEin} from './Icn/40px/Vollbild-Ein.svg';
import {ReactComponent as SvgIcn40PxWeblink} from './Icn/40px/Weblink.svg';
import {ReactComponent as SvgIcn40PxWeiteres} from './Icn/40px/Weiteres.svg';
import {ReactComponent as SvgIcn40PxWifiAus} from './Icn/40px/Wifi-Aus.svg';
import {ReactComponent as SvgIcn40PxWifi} from './Icn/40px/Wifi.svg';
import {ReactComponent as SvgIcn40PxWww} from './Icn/40px/www.svg';
import {ReactComponent as SvgIcnOverlayCollapse} from './Icn/overlay/Collapse.svg';
import {ReactComponent as SvgIcnOverlayExpand} from './Icn/overlay/Expand.svg';
import {ReactComponent as SvgIcnOverlayPlus} from './Icn/overlay/Plus.svg';
import {ReactComponent as SvgModulHeaderIPadNetz01A} from './Modul/Header-iPad/Netz-01a.svg';
import {ReactComponent as SvgModulPlaylistEmpty01A} from './Modul/Playlist/Empty-01a.svg';
import {ReactComponent as SvgModulPlaylistMap02B} from './Modul/Playlist/Map-02b.svg';
import {ReactComponent as SvgModulPlaylistOrdner02B} from './Modul/Playlist/Ordner-02b.svg';
import {ReactComponent as SvgModulPlaylistPlaylist02B} from './Modul/Playlist/Playlist-02b.svg';
import {ReactComponent as SvgShapes} from './shapes.svg';

export type SvgComponent = FunctionComponent<SVGProps<SVGSVGElement> & {title?: string | undefined}>;
export type SvgName =
  | 'DIERCKE-CD/DIERCKE_Logo_Blau'
  | 'DIERCKE-CD/DIERCKE_Logo_Weiss'
  | 'DIERCKE-CD/DIERCKE_Weltatlas_Schmetterling_Netz_Weiss'
  | 'DIERCKE-CD/Label-Westermann-01a'
  | 'DIERCKE-CD/Label-Westermann-01b'
  | 'Icn/40px/3D-Globus'
  | 'Icn/40px/3D'
  | 'Icn/40px/3Doff'
  | 'Icn/40px/Alle_Szenen'
  | 'Icn/40px/Anfasser'
  | 'Icn/40px/Auge'
  | 'Icn/40px/Ausrichten_Block'
  | 'Icn/40px/Ausrichten_L'
  | 'Icn/40px/Ausrichten_M'
  | 'Icn/40px/Ausrichten_R'
  | 'Icn/40px/Benachrichtigung'
  | 'Icn/40px/Bewegen'
  | 'Icn/40px/Browser'
  | 'Icn/40px/Cam_Rahmen'
  | 'Icn/40px/Cam'
  | 'Icn/40px/Checkbox-01'
  | 'Icn/40px/Checkbox-02'
  | 'Icn/40px/Checkhaken'
  | 'Icn/40px/Choach'
  | 'Icn/40px/Diercke_Star'
  | 'Icn/40px/Dokument'
  | 'Icn/40px/Doppelseite'
  | 'Icn/40px/Download'
  | 'Icn/40px/Drehen Copy'
  | 'Icn/40px/Drehen_Auto'
  | 'Icn/40px/Drehen'
  | 'Icn/40px/Ebenen'
  | 'Icn/40px/Einstellungen'
  | 'Icn/40px/Farbe-01'
  | 'Icn/40px/Farbe-02'
  | 'Icn/40px/Farbe-03'
  | 'Icn/40px/Fenster-Horizontal'
  | 'Icn/40px/Fenster-Sperren'
  | 'Icn/40px/Fenster-Unterteilen'
  | 'Icn/40px/Fenster-Vertikal'
  | 'Icn/40px/Filter'
  | 'Icn/40px/Fontgroesse'
  | 'Icn/40px/Fontstil'
  | 'Icn/40px/Form-01'
  | 'Icn/40px/Form-02'
  | 'Icn/40px/Form-03'
  | 'Icn/40px/Form-04'
  | 'Icn/40px/Form-05'
  | 'Icn/40px/Globen'
  | 'Icn/40px/Grafik'
  | 'Icn/40px/Groesse-Kreis-01'
  | 'Icn/40px/Groesse-Kreis-02'
  | 'Icn/40px/Groesse-Kreis-03'
  | 'Icn/40px/Groesse-Kreis-04'
  | 'Icn/40px/Groesse-Viereck-01'
  | 'Icn/40px/Groesse-Viereck-02'
  | 'Icn/40px/Groesse-Viereck-03'
  | 'Icn/40px/Groesse'
  | 'Icn/40px/Gruppe'
  | 'Icn/40px/Hand_Click'
  | 'Icn/40px/Hand_Swipe'
  | 'Icn/40px/Hilfe'
  | 'Icn/40px/Info'
  | 'Icn/40px/Karte-Wechseln'
  | 'Icn/40px/Karte'
  | 'Icn/40px/Kreis-Tool-Aktiv'
  | 'Icn/40px/Legende'
  | 'Icn/40px/Legende/Bahnhof'
  | 'Icn/40px/Legende/Bauwerk'
  | 'Icn/40px/Legende/Berg'
  | 'Icn/40px/Legende/Bergbau'
  | 'Icn/40px/Legende/Dienstleistungen'
  | 'Icn/40px/Legende/Einrichtung'
  | 'Icn/40px/Legende/Fluesse'
  | 'Icn/40px/Legende/Form-01'
  | 'Icn/40px/Legende/Form-02'
  | 'Icn/40px/Legende/Form-03'
  | 'Icn/40px/Legende/Gewaesser'
  | 'Icn/40px/Legende/Industrie'
  | 'Icn/40px/Legende/Keramik'
  | 'Icn/40px/Legende/Landschaft'
  | 'Icn/40px/Legende/Landwirtschaft'
  | 'Icn/40px/Legende/Ort'
  | 'Icn/40px/Legende/Sachwort'
  | 'Icn/40px/Legende/Staat'
  | 'Icn/40px/Legende/Titel'
  | 'Icn/40px/Legende/Tourismus'
  | 'Icn/40px/Legende/Verkehr'
  | 'Icn/40px/Legende/Volk'
  | 'Icn/40px/Legende/Wasser'
  | 'Icn/40px/Lineal'
  | 'Icn/40px/Linie-01'
  | 'Icn/40px/Linie-02'
  | 'Icn/40px/Linie-03'
  | 'Icn/40px/Linienfette-01'
  | 'Icn/40px/Linienfette-02'
  | 'Icn/40px/Linienfette-03'
  | 'Icn/40px/Linienfette-04'
  | 'Icn/40px/Liste'
  | 'Icn/40px/Lizenz-Demo'
  | 'Icn/40px/Lizenz-Kaufen'
  | 'Icn/40px/Lizenz'
  | 'Icn/40px/Loading'
  | 'Icn/40px/Meeresspiegel_Minus'
  | 'Icn/40px/Meeresspiegel_Plus'
  | 'Icn/40px/Meeresspiegel'
  | 'Icn/40px/Menue'
  | 'Icn/40px/Minus'
  | 'Icn/40px/Muelltonne'
  | 'Icn/40px/Multipanel'
  | 'Icn/40px/Navigator'
  | 'Icn/40px/Neigen'
  | 'Icn/40px/Neigung_Grade'
  | 'Icn/40px/Nord'
  | 'Icn/40px/Notiz-Tool-Aktiv'
  | 'Icn/40px/Nutzer-Angemeldet'
  | 'Icn/40px/Nutzer'
  | 'Icn/40px/Ordner'
  | 'Icn/40px/Overlay-Karte'
  | 'Icn/40px/Pfeil-Dropdown-hoch-02'
  | 'Icn/40px/Pfeil-Dropdown-hoch'
  | 'Icn/40px/Pfeil-Dropdown-rechts'
  | 'Icn/40px/Pfeil-Dropdown-runter-02'
  | 'Icn/40px/Pfeil-Dropdown-runter'
  | 'Icn/40px/Pfeil-hoch'
  | 'Icn/40px/Pfeil-L'
  | 'Icn/40px/Pfeil-R'
  | 'Icn/40px/Pfeil-runter'
  | 'Icn/40px/Pfeil-Vor'
  | 'Icn/40px/Pfeil-Zurueck'
  | 'Icn/40px/Playlist_Empty'
  | 'Icn/40px/Playlist'
  | 'Icn/40px/Playlists'
  | 'Icn/40px/Plus'
  | 'Icn/40px/POI-Text-Color'
  | 'Icn/40px/POI-Text'
  | 'Icn/40px/POI'
  | 'Icn/40px/Radiobutton-01'
  | 'Icn/40px/Radiobutton-02'
  | 'Icn/40px/Refresh'
  | 'Icn/40px/Reset'
  | 'Icn/40px/Rotieren-L'
  | 'Icn/40px/Rotieren-R'
  | 'Icn/40px/Schliesskreuz-01'
  | 'Icn/40px/Schliesskreuz-02'
  | 'Icn/40px/Schloss-Aktiv'
  | 'Icn/40px/Schloss'
  | 'Icn/40px/Schriftart'
  | 'Icn/40px/Seite'
  | 'Icn/40px/Spiel'
  | 'Icn/40px/Sprache-DE'
  | 'Icn/40px/Sprache-UK'
  | 'Icn/40px/Stempel-Tool-Aktiv'
  | 'Icn/40px/Stempel'
  | 'Icn/40px/Stempelform'
  | 'Icn/40px/Stern-02'
  | 'Icn/40px/Stern-Aktiv'
  | 'Icn/40px/Stern-Gruppe'
  | 'Icn/40px/Stern'
  | 'Icn/40px/Stift-Aktiv'
  | 'Icn/40px/Stift'
  | 'Icn/40px/Suche_Schliessen'
  | 'Icn/40px/Suche'
  | 'Icn/40px/Synchronisieren-Aus'
  | 'Icn/40px/Synchronisieren'
  | 'Icn/40px/Szene-Laden'
  | 'Icn/40px/Szene-Speichern'
  | 'Icn/40px/Szenen'
  | 'Icn/40px/Text-Tool-Aktiv'
  | 'Icn/40px/Text-Zeigen'
  | 'Icn/40px/Text'
  | 'Icn/40px/Ueberblick'
  | 'Icn/40px/Ueberhoehung_Minus'
  | 'Icn/40px/Ueberhoehung_OFF'
  | 'Icn/40px/Ueberhoehung_ON'
  | 'Icn/40px/Ueberhoehung_Plus'
  | 'Icn/40px/Verlauf'
  | 'Icn/40px/Verschieben'
  | 'Icn/40px/Vollbild-Aus'
  | 'Icn/40px/Vollbild-Ein'
  | 'Icn/40px/Weblink'
  | 'Icn/40px/Weiteres'
  | 'Icn/40px/Wifi-Aus'
  | 'Icn/40px/Wifi'
  | 'Icn/40px/www'
  | 'Icn/overlay/Collapse'
  | 'Icn/overlay/Expand'
  | 'Icn/overlay/Plus'
  | 'Modul/Header-iPad/Netz-01a'
  | 'Modul/Playlist/Empty-01a'
  | 'Modul/Playlist/Map-02b'
  | 'Modul/Playlist/Ordner-02b'
  | 'Modul/Playlist/Playlist-02b'
  | 'shapes';

// prettier-ignore
const svgs: Record<SvgName, SvgComponent> = {
  'DIERCKE-CD/DIERCKE_Logo_Blau': SvgDierckeCdDierckeLogoBlau,
  'DIERCKE-CD/DIERCKE_Logo_Weiss': SvgDierckeCdDierckeLogoWeiss,
  'DIERCKE-CD/DIERCKE_Weltatlas_Schmetterling_Netz_Weiss': SvgDierckeCdDierckeWeltatlasSchmetterlingNetzWeiss,
  'DIERCKE-CD/Label-Westermann-01a': SvgDierckeCdLabelWestermann01A,
  'DIERCKE-CD/Label-Westermann-01b': SvgDierckeCdLabelWestermann01B,
  'Icn/40px/3D-Globus': SvgIcn40Px3DGlobus,
  'Icn/40px/3D': SvgIcn40Px3D,
  'Icn/40px/3Doff': SvgIcn40Px3Doff,
  'Icn/40px/Alle_Szenen': SvgIcn40PxAlleSzenen,
  'Icn/40px/Anfasser': SvgIcn40PxAnfasser,
  'Icn/40px/Auge': SvgIcn40PxAuge,
  'Icn/40px/Ausrichten_Block': SvgIcn40PxAusrichtenBlock,
  'Icn/40px/Ausrichten_L': SvgIcn40PxAusrichtenL,
  'Icn/40px/Ausrichten_M': SvgIcn40PxAusrichtenM,
  'Icn/40px/Ausrichten_R': SvgIcn40PxAusrichtenR,
  'Icn/40px/Benachrichtigung': SvgIcn40PxBenachrichtigung,
  'Icn/40px/Bewegen': SvgIcn40PxBewegen,
  'Icn/40px/Browser': SvgIcn40PxBrowser,
  'Icn/40px/Cam_Rahmen': SvgIcn40PxCamRahmen,
  'Icn/40px/Cam': SvgIcn40PxCam,
  'Icn/40px/Checkbox-01': SvgIcn40PxCheckbox01,
  'Icn/40px/Checkbox-02': SvgIcn40PxCheckbox02,
  'Icn/40px/Checkhaken': SvgIcn40PxCheckhaken,
  'Icn/40px/Choach': SvgIcn40PxChoach,
  'Icn/40px/Diercke_Star': SvgIcn40PxDierckeStar,
  'Icn/40px/Dokument': SvgIcn40PxDokument,
  'Icn/40px/Doppelseite': SvgIcn40PxDoppelseite,
  'Icn/40px/Download': SvgIcn40PxDownload,
  'Icn/40px/Drehen Copy': SvgIcn40PxDrehenCopy,
  'Icn/40px/Drehen_Auto': SvgIcn40PxDrehenAuto,
  'Icn/40px/Drehen': SvgIcn40PxDrehen,
  'Icn/40px/Ebenen': SvgIcn40PxEbenen,
  'Icn/40px/Einstellungen': SvgIcn40PxEinstellungen,
  'Icn/40px/Farbe-01': SvgIcn40PxFarbe01,
  'Icn/40px/Farbe-02': SvgIcn40PxFarbe02,
  'Icn/40px/Farbe-03': SvgIcn40PxFarbe03,
  'Icn/40px/Fenster-Horizontal': SvgIcn40PxFensterHorizontal,
  'Icn/40px/Fenster-Sperren': SvgIcn40PxFensterSperren,
  'Icn/40px/Fenster-Unterteilen': SvgIcn40PxFensterUnterteilen,
  'Icn/40px/Fenster-Vertikal': SvgIcn40PxFensterVertikal,
  'Icn/40px/Filter': SvgIcn40PxFilter,
  'Icn/40px/Fontgroesse': SvgIcn40PxFontgroesse,
  'Icn/40px/Fontstil': SvgIcn40PxFontstil,
  'Icn/40px/Form-01': SvgIcn40PxForm01,
  'Icn/40px/Form-02': SvgIcn40PxForm02,
  'Icn/40px/Form-03': SvgIcn40PxForm03,
  'Icn/40px/Form-04': SvgIcn40PxForm04,
  'Icn/40px/Form-05': SvgIcn40PxForm05,
  'Icn/40px/Globen': SvgIcn40PxGloben,
  'Icn/40px/Grafik': SvgIcn40PxGrafik,
  'Icn/40px/Groesse-Kreis-01': SvgIcn40PxGroesseKreis01,
  'Icn/40px/Groesse-Kreis-02': SvgIcn40PxGroesseKreis02,
  'Icn/40px/Groesse-Kreis-03': SvgIcn40PxGroesseKreis03,
  'Icn/40px/Groesse-Kreis-04': SvgIcn40PxGroesseKreis04,
  'Icn/40px/Groesse-Viereck-01': SvgIcn40PxGroesseViereck01,
  'Icn/40px/Groesse-Viereck-02': SvgIcn40PxGroesseViereck02,
  'Icn/40px/Groesse-Viereck-03': SvgIcn40PxGroesseViereck03,
  'Icn/40px/Groesse': SvgIcn40PxGroesse,
  'Icn/40px/Gruppe': SvgIcn40PxGruppe,
  'Icn/40px/Hand_Click': SvgIcn40PxHandClick,
  'Icn/40px/Hand_Swipe': SvgIcn40PxHandSwipe,
  'Icn/40px/Hilfe': SvgIcn40PxHilfe,
  'Icn/40px/Info': SvgIcn40PxInfo,
  'Icn/40px/Karte-Wechseln': SvgIcn40PxKarteWechseln,
  'Icn/40px/Karte': SvgIcn40PxKarte,
  'Icn/40px/Kreis-Tool-Aktiv': SvgIcn40PxKreisToolAktiv,
  'Icn/40px/Legende': SvgIcn40PxLegende,
  'Icn/40px/Legende/Bahnhof': SvgIcn40PxLegendeBahnhof,
  'Icn/40px/Legende/Bauwerk': SvgIcn40PxLegendeBauwerk,
  'Icn/40px/Legende/Berg': SvgIcn40PxLegendeBerg,
  'Icn/40px/Legende/Bergbau': SvgIcn40PxLegendeBergbau,
  'Icn/40px/Legende/Dienstleistungen': SvgIcn40PxLegendeDienstleistungen,
  'Icn/40px/Legende/Einrichtung': SvgIcn40PxLegendeEinrichtung,
  'Icn/40px/Legende/Fluesse': SvgIcn40PxLegendeFluesse,
  'Icn/40px/Legende/Form-01': SvgIcn40PxLegendeForm01,
  'Icn/40px/Legende/Form-02': SvgIcn40PxLegendeForm02,
  'Icn/40px/Legende/Form-03': SvgIcn40PxLegendeForm03,
  'Icn/40px/Legende/Gewaesser': SvgIcn40PxLegendeGewaesser,
  'Icn/40px/Legende/Industrie': SvgIcn40PxLegendeIndustrie,
  'Icn/40px/Legende/Keramik': SvgIcn40PxLegendeKeramik,
  'Icn/40px/Legende/Landschaft': SvgIcn40PxLegendeLandschaft,
  'Icn/40px/Legende/Landwirtschaft': SvgIcn40PxLegendeLandwirtschaft,
  'Icn/40px/Legende/Ort': SvgIcn40PxLegendeOrt,
  'Icn/40px/Legende/Sachwort': SvgIcn40PxLegendeSachwort,
  'Icn/40px/Legende/Staat': SvgIcn40PxLegendeStaat,
  'Icn/40px/Legende/Titel': SvgIcn40PxLegendeTitel,
  'Icn/40px/Legende/Tourismus': SvgIcn40PxLegendeTourismus,
  'Icn/40px/Legende/Verkehr': SvgIcn40PxLegendeVerkehr,
  'Icn/40px/Legende/Volk': SvgIcn40PxLegendeVolk,
  'Icn/40px/Legende/Wasser': SvgIcn40PxLegendeWasser,
  'Icn/40px/Lineal': SvgIcn40PxLineal,
  'Icn/40px/Linie-01': SvgIcn40PxLinie01,
  'Icn/40px/Linie-02': SvgIcn40PxLinie02,
  'Icn/40px/Linie-03': SvgIcn40PxLinie03,
  'Icn/40px/Linienfette-01': SvgIcn40PxLinienfette01,
  'Icn/40px/Linienfette-02': SvgIcn40PxLinienfette02,
  'Icn/40px/Linienfette-03': SvgIcn40PxLinienfette03,
  'Icn/40px/Linienfette-04': SvgIcn40PxLinienfette04,
  'Icn/40px/Liste': SvgIcn40PxListe,
  'Icn/40px/Lizenz-Demo': SvgIcn40PxLizenzDemo,
  'Icn/40px/Lizenz-Kaufen': SvgIcn40PxLizenzKaufen,
  'Icn/40px/Lizenz': SvgIcn40PxLizenz,
  'Icn/40px/Loading': SvgIcn40PxLoading,
  'Icn/40px/Meeresspiegel_Minus': SvgIcn40PxMeeresspiegelMinus,
  'Icn/40px/Meeresspiegel_Plus': SvgIcn40PxMeeresspiegelPlus,
  'Icn/40px/Meeresspiegel': SvgIcn40PxMeeresspiegel,
  'Icn/40px/Menue': SvgIcn40PxMenue,
  'Icn/40px/Minus': SvgIcn40PxMinus,
  'Icn/40px/Muelltonne': SvgIcn40PxMuelltonne,
  'Icn/40px/Multipanel': SvgIcn40PxMultipanel,
  'Icn/40px/Navigator': SvgIcn40PxNavigator,
  'Icn/40px/Neigen': SvgIcn40PxNeigen,
  'Icn/40px/Neigung_Grade': SvgIcn40PxNeigungGrade,
  'Icn/40px/Nord': SvgIcn40PxNord,
  'Icn/40px/Notiz-Tool-Aktiv': SvgIcn40PxNotizToolAktiv,
  'Icn/40px/Nutzer-Angemeldet': SvgIcn40PxNutzerAngemeldet,
  'Icn/40px/Nutzer': SvgIcn40PxNutzer,
  'Icn/40px/Ordner': SvgIcn40PxOrdner,
  'Icn/40px/Overlay-Karte': SvgIcn40PxOverlayKarte,
  'Icn/40px/Pfeil-Dropdown-hoch-02': SvgIcn40PxPfeilDropdownHoch02,
  'Icn/40px/Pfeil-Dropdown-hoch': SvgIcn40PxPfeilDropdownHoch,
  'Icn/40px/Pfeil-Dropdown-rechts': SvgIcn40PxPfeilDropdownRechts,
  'Icn/40px/Pfeil-Dropdown-runter-02': SvgIcn40PxPfeilDropdownRunter02,
  'Icn/40px/Pfeil-Dropdown-runter': SvgIcn40PxPfeilDropdownRunter,
  'Icn/40px/Pfeil-hoch': SvgIcn40PxPfeilHoch,
  'Icn/40px/Pfeil-L': SvgIcn40PxPfeilL,
  'Icn/40px/Pfeil-R': SvgIcn40PxPfeilR,
  'Icn/40px/Pfeil-runter': SvgIcn40PxPfeilRunter,
  'Icn/40px/Pfeil-Vor': SvgIcn40PxPfeilVor,
  'Icn/40px/Pfeil-Zurueck': SvgIcn40PxPfeilZurueck,
  'Icn/40px/Playlist_Empty': SvgIcn40PxPlaylistEmpty,
  'Icn/40px/Playlist': SvgIcn40PxPlaylist,
  'Icn/40px/Playlists': SvgIcn40PxPlaylists,
  'Icn/40px/Plus': SvgIcn40PxPlus,
  'Icn/40px/POI-Text-Color': SvgIcn40PxPoiTextColor,
  'Icn/40px/POI-Text': SvgIcn40PxPoiText,
  'Icn/40px/POI': SvgIcn40PxPoi,
  'Icn/40px/Radiobutton-01': SvgIcn40PxRadiobutton01,
  'Icn/40px/Radiobutton-02': SvgIcn40PxRadiobutton02,
  'Icn/40px/Refresh': SvgIcn40PxRefresh,
  'Icn/40px/Reset': SvgIcn40PxReset,
  'Icn/40px/Rotieren-L': SvgIcn40PxRotierenL,
  'Icn/40px/Rotieren-R': SvgIcn40PxRotierenR,
  'Icn/40px/Schliesskreuz-01': SvgIcn40PxSchliesskreuz01,
  'Icn/40px/Schliesskreuz-02': SvgIcn40PxSchliesskreuz02,
  'Icn/40px/Schloss-Aktiv': SvgIcn40PxSchlossAktiv,
  'Icn/40px/Schloss': SvgIcn40PxSchloss,
  'Icn/40px/Schriftart': SvgIcn40PxSchriftart,
  'Icn/40px/Seite': SvgIcn40PxSeite,
  'Icn/40px/Spiel': SvgIcn40PxSpiel,
  'Icn/40px/Sprache-DE': SvgIcn40PxSpracheDe,
  'Icn/40px/Sprache-UK': SvgIcn40PxSpracheUk,
  'Icn/40px/Stempel-Tool-Aktiv': SvgIcn40PxStempelToolAktiv,
  'Icn/40px/Stempel': SvgIcn40PxStempel,
  'Icn/40px/Stempelform': SvgIcn40PxStempelform,
  'Icn/40px/Stern-02': SvgIcn40PxStern02,
  'Icn/40px/Stern-Aktiv': SvgIcn40PxSternAktiv,
  'Icn/40px/Stern-Gruppe': SvgIcn40PxSternGruppe,
  'Icn/40px/Stern': SvgIcn40PxStern,
  'Icn/40px/Stift-Aktiv': SvgIcn40PxStiftAktiv,
  'Icn/40px/Stift': SvgIcn40PxStift,
  'Icn/40px/Suche_Schliessen': SvgIcn40PxSucheSchliessen,
  'Icn/40px/Suche': SvgIcn40PxSuche,
  'Icn/40px/Synchronisieren-Aus': SvgIcn40PxSynchronisierenAus,
  'Icn/40px/Synchronisieren': SvgIcn40PxSynchronisieren,
  'Icn/40px/Szene-Laden': SvgIcn40PxSzeneLaden,
  'Icn/40px/Szene-Speichern': SvgIcn40PxSzeneSpeichern,
  'Icn/40px/Szenen': SvgIcn40PxSzenen,
  'Icn/40px/Text-Tool-Aktiv': SvgIcn40PxTextToolAktiv,
  'Icn/40px/Text-Zeigen': SvgIcn40PxTextZeigen,
  'Icn/40px/Text': SvgIcn40PxText,
  'Icn/40px/Ueberblick': SvgIcn40PxUeberblick,
  'Icn/40px/Ueberhoehung_Minus': SvgIcn40PxUeberhoehungMinus,
  'Icn/40px/Ueberhoehung_OFF': SvgIcn40PxUeberhoehungOff,
  'Icn/40px/Ueberhoehung_ON': SvgIcn40PxUeberhoehungOn,
  'Icn/40px/Ueberhoehung_Plus': SvgIcn40PxUeberhoehungPlus,
  'Icn/40px/Verlauf': SvgIcn40PxVerlauf,
  'Icn/40px/Verschieben': SvgIcn40PxVerschieben,
  'Icn/40px/Vollbild-Aus': SvgIcn40PxVollbildAus,
  'Icn/40px/Vollbild-Ein': SvgIcn40PxVollbildEin,
  'Icn/40px/Weblink': SvgIcn40PxWeblink,
  'Icn/40px/Weiteres': SvgIcn40PxWeiteres,
  'Icn/40px/Wifi-Aus': SvgIcn40PxWifiAus,
  'Icn/40px/Wifi': SvgIcn40PxWifi,
  'Icn/40px/www': SvgIcn40PxWww,
  'Icn/overlay/Collapse': SvgIcnOverlayCollapse,
  'Icn/overlay/Expand': SvgIcnOverlayExpand,
  'Icn/overlay/Plus': SvgIcnOverlayPlus,
  'Modul/Header-iPad/Netz-01a': SvgModulHeaderIPadNetz01A,
  'Modul/Playlist/Empty-01a': SvgModulPlaylistEmpty01A,
  'Modul/Playlist/Map-02b': SvgModulPlaylistMap02B,
  'Modul/Playlist/Ordner-02b': SvgModulPlaylistOrdner02B,
  'Modul/Playlist/Playlist-02b': SvgModulPlaylistPlaylist02B,
  'shapes': SvgShapes,
};

export default svgs;
