import {ScreenSpaceCameraController} from 'resium';
import * as Cesium from 'cesium';

import {useGlobus} from './GlobusProvider';

export const CameraController: React.FC = () => {
  const {defaultMovementMode} = useGlobus();
  return (
    <ScreenSpaceCameraController
      translateEventTypes={{} as any}
      zoomEventTypes={[
        Cesium.CameraEventType.RIGHT_DRAG,
        Cesium.CameraEventType.WHEEL,
        Cesium.CameraEventType.PINCH,
        ...(defaultMovementMode === 'zoom' ? [Cesium.CameraEventType.LEFT_DRAG] : []),
      ]}
      rotateEventTypes={defaultMovementMode === 'rotate' ? [Cesium.CameraEventType.LEFT_DRAG] : []}
      tiltEventTypes={[
        Cesium.CameraEventType.MIDDLE_DRAG,
        {eventType: Cesium.CameraEventType.LEFT_DRAG, modifier: Cesium.KeyboardEventModifier.CTRL},
        {eventType: Cesium.CameraEventType.RIGHT_DRAG, modifier: Cesium.KeyboardEventModifier.CTRL},
        Cesium.CameraEventType.PINCH,
        ...(defaultMovementMode === 'tilt' ? [Cesium.CameraEventType.LEFT_DRAG] : []),
      ]}
      lookEventTypes={[
        {eventType: Cesium.CameraEventType.LEFT_DRAG, modifier: Cesium.KeyboardEventModifier.SHIFT},
        ...(defaultMovementMode === 'look' ? [Cesium.CameraEventType.LEFT_DRAG] : []),
      ]}
    />
  );
};
