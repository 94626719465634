import {useTranslation} from 'react-i18next';

import {useGlobus} from '../GlobusProvider';

import OverlayButton from './OverlayButton';

// we need to pass some hidden props to the button
const ZoomInButton: React.FC = (props) => {
  const {t} = useTranslation();
  const {controller} = useGlobus();
  return <OverlayButton onClick={controller?.zoomIn} icon="Plus" title={t('globus:description.zoomIn')} {...props} />;
};

export default ZoomInButton;
