export type Vec4<T = number> = [T, T, T, T];

export function isArrayVec4<T>(arr: Array<T>): arr is Vec4<T> {
  return arr.length === 4;
}

export function arrayToVec4<T>(arr: Array<T>): undefined | Vec4<T> {
  if (isArrayVec4(arr)) {
    return arr as Vec4<T>;
  } else {
    return undefined;
  }
}
