import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';

import {useIsKmzAvailable, useUserData} from '../../../api/data';
import {useHandleLoginLogout} from '../../top-bar/TopBar';
import Button from '../../shared/Button';
import Spinner from '../../shared/Spinner';

import SerialKeyInputQuery, {SerialKeyInputQueryProps} from './SerialKeyInputQuery';

type Props = {kmzId: number | null} & SerialKeyInputQueryProps;

const Activation: React.FC<Props> = ({onIsActivated, kmzId}) => {
  const {t} = useTranslation();
  const user = useUserData();
  const handleLoginLogout = useHandleLoginLogout();

  const [isKeyValid, setIsKeyValid] = useState(false);
  const {data: isKmzAvailable, isSuccess, isIdle, isLoading, isError} = useIsKmzAvailable(kmzId);

  useEffect(() => {
    if (onIsActivated && (isIdle || isKmzAvailable || (isSuccess && isKeyValid))) onIsActivated();
  }, [isSuccess, isIdle, isKmzAvailable, isKeyValid, onIsActivated]);

  if (isError) {
    return <div className="w-full h-full bg-white grid place-items-center">{t('description.error')}</div>;
  } else if (isLoading) {
    return (
      <div className="w-full h-full bg-white grid place-items-center">
        <Spinner className="h-28" />
      </div>
    );
  } else {
    return (
      <div className="w-[80vw] max-w-[600px] overflow-hidden rounded-xl border-diercke-blue border-[1px]">
        {/* TODO: use bg-diercke-blue-300 */}
        <h2 className="p-4 bg-diercke-blue-250 font-semibold text-diercke-blue">{t('globus:activation.header')}</h2>

        <article className="p-2 bg-white">
          <div className="p-4 bg-diercke-blue-50">
            <p className="mb-2 font-semibold">
              {user === null
                ? t('globus:activation.body')
                : `${t('globus:activation.noLicense')} ${t('globus:activation.body')}`}
            </p>

            <SerialKeyInputQuery onIsActivated={() => setIsKeyValid(true)} />
          </div>

          {user === null && (
            <>
              <div className="p-2 text-center font-normal">{t('globus:activation.or')}</div>

              <div className="p-4 bg-diercke-blue-50">
                <p className="mb-2 font-semibold">{t('globus:activation.loginBody')}</p>

                <Button
                  className="block mx-auto px-2 py-1 border border-solid border-diercke-blue-500 rounded-xl bg-white hover:bg-gray-200 font-semibold"
                  labelOptional={false}
                  onClick={handleLoginLogout}
                >
                  {t('globus:activation.toLogin')}
                </Button>
              </div>
            </>
          )}
        </article>
      </div>
    );
  }
};

export default Activation;
