import {useMemo} from 'react';
import * as Cesium from 'cesium';
import { Scene} from 'resium';

import {useGlobus} from "../GlobusProvider";

export const TerrainProvider: React.FC = () => {
  const {terrainEnabled} = useGlobus();

  const imageryProviderTerrain = useMemo(() => {
    if(terrainEnabled){
    return new Cesium.CesiumTerrainProvider({
      url: 'https://globusserver.de/cesiumterrain/terrain',
    });}else{
      return   new Cesium.EllipsoidTerrainProvider();
    }
  }, [terrainEnabled]);
  return <Scene terrainProvider={imageryProviderTerrain} />;
};
