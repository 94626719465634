import {useTranslation} from 'react-i18next';

import {useGlobus} from '../GlobusProvider';

import OverlayButton from './OverlayButton';

// we need to pass some hidden props to the button
const HeadToNorthButton: React.FC = (props) => {
  const {t} = useTranslation();
  const {controller} = useGlobus();
  return (
    <OverlayButton
      onClick={controller?.resetCameraHeadingNorth}
      icon="Nord"
      title={t('globus:description.headToNorth')}
      {...props}
    />
  );
};

export default HeadToNorthButton;
