import {useQuery} from 'react-query';
import {useEffect, useMemo, useState} from 'react';
import qs from 'qs';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';

import {getApiBase} from '../../../modules/url';
import {fetchJson} from '../../../utils/httpRequestHelper';

import SerialKeyInput, {Validity} from './SerialKeyInput';

export type SerialKeyInputQueryProps = {
  onIsActivated?: () => void;
};

const SerialKeyInputQuery: React.FC<SerialKeyInputQueryProps> = ({onIsActivated}) => {
  const {search} = useLocation();
  const mapId = useMemo(() => new URLSearchParams(search).get('kmz')?.split('.')[0], [search]);
  const {t} = useTranslation();
  const [key, setSerial] = useState<string>();
  const [validity, setValidity] = useState<Validity>();
  const result = useQuery(
    ['serial-key-validation', key],
    async () => {
      const result = await fetchJson({
        url: getApiBase() + 'validate?' + qs.stringify({key, map: mapId}),
      });
      return result.data;
    },
    {enabled: key?.length === 14}
  );

  useEffect(() => {
    if (key?.length === 14 && result.isFetched) {
      if (result.data?.valid && !!key?.match(/^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/)) {
        setValidity(result.data?.map == null || result.data.map ? Validity.VALID : Validity.DOES_NOT_MATCH_MAP);
      } else {
        setValidity(Validity.INVALID);
      }
    } else {
      setValidity(undefined);
    }
  }, [result, key, mapId]);

  useEffect(() => {
    if (onIsActivated && validity === Validity.VALID) onIsActivated();
  }, [onIsActivated, validity]);

  return (
    <>
      <SerialKeyInput onChange={(serial) => setSerial(serial)} validity={validity} />

      {/* set height to reserve space & not cause a flicker */}
      <p className="h-4 mt-2 text-center">
        {validity === Validity.VALID && t('globus:activation.keyValid')}
        {validity === Validity.DOES_NOT_MATCH_MAP && (
          <div className="text-diercke-red">{t('globus:activation.keyDoesNotMatchMap')}</div>
        )}
        {validity === Validity.INVALID && <div className="text-diercke-red">{t('globus:activation.keyInvalid')} </div>}
      </p>
    </>
  );
};

export default SerialKeyInputQuery;
