import svgs, {SvgComponent, SvgName} from '../../svg-processed';
import {ReactComponent as OverlayPlus} from '../../svg-processed/Icn/overlay/Plus.svg';
import {ReactComponent as OverlayCollapse} from '../../svg-processed/Icn/overlay/Collapse.svg';
import {ReactComponent as OverlayExpand} from '../../svg-processed/Icn/overlay/Expand.svg';

const IconSvgNamePrefix = 'Icn/40px/' as const;

export type IconOverlayType = 'plus' | 'collapse' | 'expand';
export type IconOverlayPosition = `${'top' | 'bottom'}-${'left' | 'right'}`;

export type IconName = {[P in SvgName]: P extends `${typeof IconSvgNamePrefix}${infer K}` ? K : never}[SvgName];

const overlayIconMap: Record<IconOverlayType, SvgComponent> = {
  plus: OverlayPlus,
  collapse: OverlayCollapse,
  expand: OverlayExpand,
};

function iconNameToSvgName(iconName: IconName): SvgName {
  return (IconSvgNamePrefix + iconName) as SvgName;
}

export interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  name: IconName;
  size?: '40';
  overlay?: IconOverlayType;
  /** @default 'bottom-right' */
  overlayPosition?: IconOverlayPosition;
  /** In degree */
  overlayRotation?: number;
  overlayClassName?: string;
  title?: string;
}

const Icon: React.FC<IconProps> = ({
  size = '40',
  name,
  className = '',
  overlay,
  overlayPosition = 'bottom-right',
  overlayRotation,
  overlayClassName,
  title,
  ...rest
}) => {
  const Svg: SvgComponent = svgs[iconNameToSvgName(name)];

  const img = (
    <Svg
      className={`diercke-icon aspect-square h-auto ${className}`}
      title={title}
      width={size}
      height={size}
      {...rest}
    />
  );

  if (overlay) {
    if (overlayIconMap.hasOwnProperty(overlay)) {
      const Overlay = overlayIconMap[overlay];
      const classOverlayPos =
        overlayPosition === 'top-left'
          ? 'top-0 left-0'
          : overlayPosition === 'bottom-left'
          ? 'bottom-0 left-0'
          : overlayPosition === 'top-right'
          ? 'right-0 top-0'
          : overlayPosition === 'bottom-right'
          ? 'right-0 bottom-0'
          : '';

      return (
        <div className="relative">
          {img}
          <Overlay
            className={`
              absolute
              m-px
              opacity-50
              ${classOverlayPos}
              ${overlayClassName || ''}
              ${overlayRotation !== undefined ? 'transform' : ''}
            `}
            style={{
              '--tw-rotate': overlayRotation ? `${overlayRotation}deg` : undefined,
            }}
            width={10}
            height={10}
          />
        </div>
      );
    } else {
      console.error('Unknown icon overlay ' + overlay);
    }
  }

  return img;
};

export default Icon;
