import {useMemo} from 'react';
import * as Cesium from 'cesium';
import {ImageryLayer} from 'resium';

import unreachable from '../../utils/unreachable';

import {GlobusImage, useGlobus} from './GlobusProvider';

const HERE_API_KEY = 'iGpbhAEqPP5PYILRcynUge_VpaLlNu7SSUAqcCphZoA';

type Props = {image: GlobusImage};

export const TileImageryProvider: React.FC<Props> = ({image}) => {
  const {image: selectedImage} = useGlobus();
  const imageryProvider = useMemo(() => {
    switch (image) {
      case 'sat':
        // Provide a custom URL to support Here Maps.
        // https://cesium.com/learn/cesiumjs/ref-doc/UrlTemplateImageryProvider.html
        // https://developer.here.com/documentation/map-tile/dev_guide/topics/example-satellite-map.html
        // https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/5/15/12/256/png8?apiKey={YOUR_API_KEY}
        return new Cesium.UrlTemplateImageryProvider({
          url:
            'https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/jpg?apiKey=' +
            HERE_API_KEY,
          credit: '© Here Maps',
        });

      case 'satlabels':
        return Cesium.createWorldImagery({
          style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
        });

      case 'phys':
        return new Cesium.OpenStreetMapImageryProvider({
          url: 'https://globusserver.de/cgi-bin/ImagonTileCache2.pl?mergephysical/',
          maximumLevel: 12,
          fileExtension: 'jpg',
        });

      case 'osm':
        return new Cesium.OpenStreetMapImageryProvider({
          url: 'https://b.tile.openstreetmap.org/',
        });

      case 'dierckeRoads':
        return new Cesium.OpenStreetMapImageryProvider({
          url: 'https://globusserver.de/cgi-bin/ImagonTileCache2.pl?osmroads/',
        });

      case 'dierckeBorders':
        return new Cesium.OpenStreetMapImageryProvider({
          url: 'https://globusserver.de/cgi-bin/ImagonTileCache2.pl?osmborders/',
        });

      case 'grid':
        return new Cesium.GridImageryProvider({
          tileWidth: 2048,
          tileHeight: 2048,
          color: new Cesium.Color(0.0, 1.0, 1.0, 0.1),
        });

      default:
        unreachable(image);
    }
  }, [image]);
  return <ImageryLayer imageryProvider={imageryProvider} show={selectedImage === image} />;
};
