import {CapacitorHttp, HttpOptions, HttpResponse} from '@capacitor/core';

export type RequestOptions = HttpOptions & {
  /** @default "get" */
  method?: 'get' | 'post' | 'put' | 'delete';

  /**
   * Defines whether an exception is thrown for a given status code.
   * @returns `false`: an exception is thrown
   * @default `200 <= status && status <= 299`
   */
  validateStatus?(status: number): boolean;
};

/**
 * Helper function for requests doing the following things:
 * * In the web version by default, send session cookie via 'include' rule unless specified otherwise.
 */
export async function sendRequest({
  validateStatus = (status) => 200 <= status && status <= 299,
  ...capacitorOptions
}: RequestOptions): Promise<HttpResponse> {
  capacitorOptions.webFetchExtra = {
    credentials: 'include',
    ...capacitorOptions.webFetchExtra,
  };

  if (capacitorOptions.data !== undefined && typeof capacitorOptions.data !== 'string') {
    capacitorOptions.data = JSON.stringify(capacitorOptions.data);
    capacitorOptions.headers = {
      'content-type': 'application/json',
      ...capacitorOptions.headers,
    };
  }

  const resp = await CapacitorHttp.request(capacitorOptions);

  if (!validateStatus(resp.status)) {
    throw new Error(`http fetch error: got non-success http code ${resp.status} for "${capacitorOptions.url}"`, {
      cause: resp,
    });
  }

  return resp;
}

/** Expects the server to send an json response, throws an exception otherwise. */
export async function fetchJson(options: RequestOptions): Promise<HttpResponse> {
  const opt: RequestOptions = {
    ...options,
    headers: {
      ...options.headers,
      accept: 'application/json',
    },
  };

  const resp = await sendRequest(opt);

  if (resp.headers['content-type']?.startsWith('application/json') === false) {
    throw new Error(`http fetch error: got non-json content-type for "${opt.url}"`, {cause: resp});
  }

  return resp;
}
