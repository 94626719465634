import {useEffect, useState} from 'react';

import {Breakpoints, getBreakpoints, observeBreakpoints} from '../modules/tailwind';

function useBreakpoints(): Breakpoints {
  const [breakpoints, setBreakpoints] = useState<Breakpoints>(() => getBreakpoints());
  useEffect(
    () =>
      observeBreakpoints((next) => {
        // console.log('set bp', JSON.stringify(next));
        setBreakpoints(next);
      }),
    [setBreakpoints]
  );
  return breakpoints;
}

export default useBreakpoints;
