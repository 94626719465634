import {useTranslation} from 'react-i18next';

import {useGlobus} from '../GlobusProvider';

import OverlayButton from './OverlayButton';

// we need to pass some hidden props to the button
const LookDownwardsButton: React.FC = (props) => {
  const {t} = useTranslation();
  const {controller} = useGlobus();
  return (
    <OverlayButton
      onClick={controller?.resetCameraLookDownwards}
      icon="Neigung_Grade"
      title={t('globus:description.lookDownwards')}
      {...props}
    />
  );
};

export default LookDownwardsButton;
