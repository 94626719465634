import {useTranslation} from 'react-i18next';
import {useCallback, useEffect, useState, VFC} from 'react';
import {Capacitor, CapacitorHttp} from '@capacitor/core';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser';
import {Browser} from '@capacitor/browser';
import {App} from '@capacitor/app';
import qs from 'qs';
import {useHistory} from 'react-router';

import {useUserData} from '../../api/data';
import {BASE_URL, getAuthBaseUrl} from '../../modules/url';
import {ReactComponent as BackgroundSvg} from '../../svg/Modul/Header-iPad/Netz-01a.svg';
import {ReactComponent as DierckeLogoWeiss} from '../../svg/DIERCKE-CD/DIERCKE_Logo_Weiss.svg';
import SettingsModal from '../SettingsModal';
import BarSpacer, {BarSpacerProps} from '../shared/BarSpacer';
import SearchButton from '../search/SearchButton';

import TopBarButton from './TopBarButton';

const TopBarSpacer: VFC<BarSpacerProps> = ({orientation = 'x', className = '', ...props}) => (
  <BarSpacer orientation={orientation} className={`${className} border-white/20`} {...props} />
);

const DemoBanner: VFC = () => (
  <svg className="mr-px" xmlns="http://www.w3.org/2000/svg" width="16" height="52">
    <title>Demo</title>
    <path fill="#E84624" d="M0 0h16v52H0z" />
    <path
      fill="#FFF"
      d="M8.13 35.85c.63 0 1.21.1 1.74.32a4.1 4.1 0 0 1 2.3 2.36c.22.55.33 1.17.33 1.85v3.4H3.76v-3.4c0-.68.1-1.3.33-1.85a4.06 4.06 0 0 1 2.3-2.35 4.57 4.57 0 0 1 1.74-.33Zm0 2.07c-.44 0-.83.06-1.17.17a2.5 2.5 0 0 0-.89.48 2.1 2.1 0 0 0-.56.78c-.13.3-.2.65-.2 1.03v1.36h5.63v-1.36c0-.38-.06-.73-.2-1.03a2.1 2.1 0 0 0-.55-.77 2.5 2.5 0 0 0-.89-.49 3.83 3.83 0 0 0-1.17-.17Zm-4.37-9.6h1.56v3.59h2.04v-2.75h1.5v2.75h2.08v-3.59h1.56v5.63H3.76zm4.74-6.54a8.7 8.7 0 0 1 .98-.44 9.18 9.18 0 0 1-1-.45l-4.41-2.25a.73.73 0 0 1-.18-.12.4.4 0 0 1-.09-.14.6.6 0 0 1-.04-.18v-1.78h8.74v1.79H7.47c-.24 0-.5-.01-.79-.04l4.5 2.32a.76.76 0 0 1 .42.7v.28a.76.76 0 0 1-.43.7l-4.5 2.34a7.49 7.49 0 0 1 .8-.05h5.03v1.79H3.76v-1.78a.6.6 0 0 1 .04-.18.4.4 0 0 1 .1-.14l.17-.12 4.43-2.25ZM8.13 5.43c.63 0 1.22.11 1.76.33a4.25 4.25 0 0 1 2.7 4.2c0 .69-.1 1.3-.34 1.87a4.25 4.25 0 0 1-4.12 2.68 4.7 4.7 0 0 1-1.77-.32A4.2 4.2 0 0 1 4 11.83a4.89 4.89 0 0 1-.34-1.86c0-.68.12-1.3.35-1.86a4.28 4.28 0 0 1 4.12-2.68Zm0 2.08c-.44 0-.82.05-1.17.16-.35.12-.64.28-.88.49-.24.2-.42.46-.55.77-.13.3-.2.65-.2 1.04s.07.74.2 1.04c.13.31.31.57.55.78.24.2.53.37.88.48.35.11.73.17 1.17.17.43 0 .82-.06 1.17-.17.34-.11.64-.27.88-.48.24-.21.42-.47.55-.78a2.8 2.8 0 0 0-.01-2.08 2 2 0 0 0-.54-.77 2.47 2.47 0 0 0-.88-.49 3.8 3.8 0 0 0-1.17-.16Z"
    />
  </svg>
);

export function getRedirectUrl(): string {
  if (Capacitor.isNativePlatform()) {
    return 'dierckewebapp://close_browser_after_redirect';
  } else {
    // return BASE_URL.href;
    return window.location.href;
  }
}

export function getLoginLogoutUrl(auth: 'login' | 'logout'): string {
  return (
    getAuthBaseUrl() +
    (auth === 'login'
      ? `/init?iss=${encodeURIComponent('https://wgr.de')}&login_redirect_uri=${encodeURIComponent(getRedirectUrl())}`
      : `/deinit?logout_redirect_uri=${encodeURIComponent(getRedirectUrl())}`)
  );
}

export function useHandleLoginLogout() {
  // Close the Capacitor-browser after successful login
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const urlOpenHandle = App.addListener('appUrlOpen', async (data) => {
        const parts = data.url.split('?');
        if (parts[0] === getRedirectUrl()) {
          if (Capacitor.getPlatform() === 'ios') {
            await Browser.close();
          }
          const query = qs.parse(String(parts[1]));
          await CapacitorHttp.get({
            url: `${getAuthBaseUrl()}/validate?state=${encodeURIComponent(String(query.state))}`,
          });
          window.location.reload();
        }
      });

      return () => {
        urlOpenHandle.remove();
      };
    }
  }, []);

  const user = useUserData();

  return useCallback(() => {
    const url = getLoginLogoutUrl(user === null ? 'login' : 'logout');
    if (Capacitor.isNativePlatform()) {
      //if (Capacitor.getPlatform() === 'ios') {
      //
      // Use either the Capacitor-browser-plugin or
      // the Cordova-browser-plugin
      //
      const useCapacitorBrowser: boolean = true;

      if (useCapacitorBrowser) {
        //
        // Capacitor Browser:
        //
        Browser.open({url: url}).catch(console.error);
      } else {
        //
        // Cordova Browser:
        //
        const browser = InAppBrowser.create(url, '_self', {
          location: 'no',
        });
        browser.on('loadstart').subscribe((event) => {
          if (event.url === BASE_URL.href) {
            browser.close();
            window.location.reload();
          }
        });
      }
    } else {
      window.location.href = url;
    }
  }, [user]);
}

const TopBar: React.FC = () => {
  const {t} = useTranslation();
  const {goBack: historyHoBack} = useHistory();

  const user = useUserData();

  const [isSettingsOpen, setSettingsOpen] = useState(false);

  const handleLoginLogout = useHandleLoginLogout();

  const handleBackClick = useCallback(() => historyHoBack(), [historyHoBack]);

  return (
    <div className="flex relative z-30 bg-diercke-blue text-white justify-between border-b-2 border-b-diercke-red">
      <div className="hidden lg:block absolute inset-0 -full h-full overflow-hidden">
        <BackgroundSvg preserveAspectRatio="xMidYMid slice" className="diercke-icon w-[calc(100%-22px)] text-white" />
      </div>

      <div className="flex h-full align-middle items-center absolute left-1/2 -translate-x-1/2 lg:ml-[22px]">
        <DierckeLogoWeiss className="lg:w-[103px] lg:h-[40px]" width="85" height="32" />
        <span className="pr-2 pl-1 hidden md:block">Globus</span>
      </div>

      <div className="flex relative h-[52px]">
        {user === null ? <DemoBanner /> : <div className="w-1" />}

        <div className="flex-grow flex gap-2">
          <TopBarButton title={t('description.back')} onClick={handleBackClick}>
            {t('description.back')}
          </TopBarButton>
          <TopBarSpacer />
          <TopBarButton icon={user !== null ? 'Nutzer-Angemeldet' : 'Nutzer'} onClick={handleLoginLogout}>
            {user !== null ? `${t('globus:description.logout')}` : t('globus:description.login')}
          </TopBarButton>
        </div>
      </div>

      <div className="flex relative">
        <TopBarButton title={t('description.help')} icon="Hilfe" className="hidden md:block" />
        <TopBarSpacer />
        <TopBarButton title={t('description.help')} icon="Einstellungen" onClick={() => setSettingsOpen(true)} />
        <TopBarSpacer />
        <SearchButton />
      </div>

      <SettingsModal isOpen={isSettingsOpen} onRequestClose={() => setSettingsOpen(false)} />
    </div>
  );
};

export default TopBar;
