import {useMemo} from 'react';
import * as Cesium from 'cesium';
import {ImageryLayer} from 'resium';

import {useGlobus} from './GlobusProvider';

export const BorderImageryProvider: React.FC = () => {
  const {bordersEnabled: enabled} = useGlobus();

  const imageryProviderBorders = useMemo(() => {
    return new Cesium.OpenStreetMapImageryProvider({
      url: 'https://globusserver.de/cgi-bin/ImagonTileCache2.pl?osmborders/',
    });
  }, []);
  return <ImageryLayer show={enabled} imageryProvider={imageryProviderBorders} />;
};
