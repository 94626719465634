import {RefObject, useEffect, useRef, useState} from 'react';
import {
  AriaSliderProps,
  mergeProps,
  useFocusRing,
  useNumberFormatter,
  useSlider,
  useSliderThumb,
  VisuallyHidden,
} from 'react-aria';
import {useTranslation} from 'react-i18next';
import {SliderState, useSliderState} from 'react-stately';

import {useGlobus} from '../GlobusProvider';

const OverlayMapTransparency = () => {
  const {t} = useTranslation();
  const numberFormatter = useNumberFormatter();
  const {overlayMap, setGlobus} = useGlobus();
  const trackRef = useRef<HTMLDivElement>(null);
  const [opacity, setOpacity] = useState(overlayMap?.opacity ?? 1);

  useEffect(
    () =>
      setGlobus((globus) => {
        if (globus.overlayMap !== null) {
          globus.overlayMap.isVisible = true;
          globus.overlayMap.opacity = opacity;
        }
      }),
    [setGlobus, opacity]
  );

  useEffect(() => setOpacity(overlayMap?.opacity ?? 1), [overlayMap]);

  const sliderProps: AriaSliderProps<number> = {
    minValue: 0,
    maxValue: 1,
    step: 0.01,
    orientation: 'horizontal',
    onChange: setOpacity,
    'aria-label': t('globus:description.transparency'),
    defaultValue: opacity,
  };
  const state = useSliderState({...sliderProps, numberFormatter});
  const {groupProps, trackProps} = useSlider(sliderProps, state, trackRef);

  return (
    <div {...groupProps} className="p-2">
      <div className="text-center">{t('globus:description.transparency')}</div>
      <div className="px-4 my-4 py-2 bg-diercke-blue-250 rounded-full" >
        <div {...trackProps} ref={trackRef} className="relative h-1 bg-diercke-blue-300 rounded-full">
          <div className="h-full bg-diercke-blue-400 rounded-full" style={{width: `${opacity * 100}%`}} />
          <Thumb state={state} trackRef={trackRef} />
        </div>
      </div>
    </div>
  );
};

export default OverlayMapTransparency;

type ThumbProps = {
  state: SliderState;
  trackRef: RefObject<Element>;
};

const Thumb: React.FC<ThumbProps> = ({state, trackRef}) => {
  const inputRef = useRef(null);
  const {thumbProps, inputProps, isDragging} = useSliderThumb(
    {
      index: 0,
      trackRef,
      inputRef,
    },
    state
  );
  const {focusProps, isFocusVisible} = useFocusRing();

  return (
    <div
      {...thumbProps}
      className={`
      top-1/2 w-8 h-8 rounded-full cursor-ew-resize
      border-diercke-blue-400 border-[2px]
      ${isFocusVisible || isDragging ? 'bg-diercke-blue-500' : 'bg-white'}
      `}
    >
      <VisuallyHidden>
        <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
      </VisuallyHidden>
    </div>
  );
};
