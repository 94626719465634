import BarSpacer from '../../shared/BarSpacer';
import {useGlobus} from '../GlobusProvider';

import LayerComp from './GlobusLayer';

const GlobusLayers: React.FC = () => {
  const {overlayMap} = useGlobus();

  if (overlayMap === null) {
    return null;
  }

  return (
    <ul>
      {overlayMap.layers.order.map((id) => (
        <li key={id}>
          <LayerComp id={id} />
          <BarSpacer orientation="y" />
        </li>
      ))}
    </ul>
  );
};

export default GlobusLayers;
