import {useCallback, useState} from 'react';

import {IconName} from '../../shared/Icon';

import OverlayButton from './OverlayButton';
import {mapButtonGroupChildren, OptionalReactElement} from './OverlayButtonGroup';

type Props = {
  orientation?: 'x' | 'y';
  icon: IconName;
  children: OptionalReactElement | OptionalReactElement[];
};

const OverlayPopoverButton: React.FC<Props> = ({
  children = [],
  orientation,
  icon,
  // we need to pass some hidden props to the button
  ...rest
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const togglePopoverOpen = useCallback(() => setIsPopoverOpen((prev) => !prev), []);

  children = Array.isArray(children) ? children : [children];

  const childrenOrientation = orientation === 'x' ? 'y' : 'x';

  return (
    <OverlayButton
      onClick={togglePopoverOpen}
      isPopoverOpen={isPopoverOpen}
      icon={icon}
      iconOverlay={isPopoverOpen ? 'collapse' : 'plus'}
      iconOverlayPosition={orientation === 'x' ? 'top-right' : 'bottom-left'}
      iconOverlayRotation={orientation === 'x' ? 270 : 180}
      popoverContent={
        <div className={`flex select-none pointer-events-auto`}>
          {/* TODO: use React.Children.map instead? */}
          {children.map((element, index) => mapButtonGroupChildren(element, index, childrenOrientation))}
        </div>
      }
      popoverPosition={orientation === 'x' ? 'top' : 'left'}
      orientation={orientation}
      {...rest}
    />
  );
};

export default OverlayPopoverButton;
