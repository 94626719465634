import {motion, useMotionValue} from 'framer-motion';
import type {BoundingBox, HTMLMotionProps, MotionValue} from 'framer-motion';
import {RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';

import throttle from '../../utils/throttle';

function usePaddedWindowDragConstraints(
  padding: [number, number, number, number],
  canvasRef: RefObject<HTMLDivElement | null>,
  windowRef: RefObject<HTMLDivElement | null>
): Partial<BoundingBox> {
  const [top, right, bottom, left] = padding;
  const [constraints, setConstraints] = useState<Partial<BoundingBox>>({});

  const updateConstraints = useCallback(() => {
    const canvasWidth = canvasRef.current?.offsetWidth;
    const canvasHeight = canvasRef.current?.offsetHeight;
    const windowWidth = windowRef.current?.offsetWidth;
    const windowHeight = windowRef.current?.offsetHeight;

    setConstraints({
      top,
      left,
      right: canvasWidth && windowWidth ? canvasWidth - right - windowWidth : undefined,
      bottom: canvasHeight && windowHeight ? canvasHeight - bottom - windowHeight : undefined,
    });
  }, [canvasRef, windowRef, top, left, right, bottom]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(throttle(updateConstraints));

    if (canvasRef.current) {
      resizeObserver.observe(canvasRef.current);
    }

    if (windowRef.current) {
      resizeObserver.observe(windowRef.current);
    }

    updateConstraints();

    return () => {
      resizeObserver.disconnect();
    };
  }, [canvasRef, windowRef, updateConstraints]);

  return constraints;
}

function useConstrainedDragMotionValues(
  constraints: Partial<BoundingBox>,
  defaultX: number = 0,
  defaultY: number = 0
): {x: MotionValue; y: MotionValue} {
  const {top = 0, right, bottom, left = 0} = constraints;
  const x = useMotionValue(defaultX);
  const y = useMotionValue(defaultY);
  const xVal = x.get();
  const yVal = y.get();

  const applyConstraints = useCallback(() => {
    if (!right || !bottom) {
      return;
    }

    const constrainedX = Math.min(Math.max(left, xVal < 0 ? right + xVal : xVal), right);

    if (xVal !== constrainedX) {
      x.set(constrainedX);
    }

    const constrainedY = Math.min(Math.max(top, yVal < 0 ? bottom + yVal : yVal), bottom);

    if (yVal !== constrainedY) {
      y.set(constrainedY);
    }
  }, [top, left, bottom, right, xVal, yVal, x, y]);

  useEffect(applyConstraints, [applyConstraints]);

  return {x, y};
}

type Props = HTMLMotionProps<'div'> & {
  padding: [number, number, number, number];
  visible?: boolean;
  defaultX?: number;
  defaultY?: number;
};

const Window: React.FC<Props> = ({className = '', padding, children, defaultX = 0, defaultY = 0, style, ...props}) => {
  const attrs = props as HTMLMotionProps<'div'>;
  const canvasRef = useRef<HTMLDivElement>(null);
  const windowElementRef = useRef<HTMLDivElement>(null);
  const dragConstraints = usePaddedWindowDragConstraints(padding, canvasRef, windowElementRef);
  const {x, y} = useConstrainedDragMotionValues(dragConstraints, defaultX, defaultY);

  return (
    <motion.div ref={canvasRef} className="absolute inset-0 pointer-events-none">
      <motion.div
        ref={windowElementRef}
        className={`relative pointer-events-auto bg-white z-40 border border-black rounded shadow-xl overflow-hidden ${className}`}
        drag
        dragMomentum={false}
        dragConstraints={dragConstraints}
        style={{...style, x, y}}
        {...attrs}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default Window;
