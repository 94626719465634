import BarSpacer, {BarSpacerProps} from '../../shared/BarSpacer';

const OverlayButtonGroupSpacer: React.FC<BarSpacerProps> = ({orientation = 'x', className = '', ...rest}) => (
  <div className="bg-white/80 py-px">
    <BarSpacer
      className={`${orientation === 'x' ? 'my-2' : 'mx-2'} ${className}`}
      orientation={orientation}
      {...rest}
    />
  </div>
);

export default OverlayButtonGroupSpacer;
