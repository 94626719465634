import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {useGlobus} from '../GlobusProvider';

import OverlayButton from './OverlayButton';

// we need to pass some hidden props to the button
const TerrainButton: React.FC = (props) => {
  const {t} = useTranslation();
  const {terrainEnabled, setGlobus} = useGlobus();
  const handleClick = useCallback(
    () =>
      setGlobus((globus) => {
        globus.terrainEnabled = !globus.terrainEnabled;
      }),
    [setGlobus]
  );
  return (
    <OverlayButton
      onClick={handleClick}
      icon={terrainEnabled ? 'Ueberhoehung_OFF' : 'Ueberhoehung_ON'}
      title={t('globus:description.toggleElevationModel')}
      {...props}
    />
  );
};

export default TerrainButton;
