import * as Cesium from 'cesium';
import {useCesium} from 'resium';
import {useEffect} from 'react';

import {useGlobus} from './GlobusProvider';

export type GlobusController = Readonly<{
  resize(): void;
  zoomIn(): void;
  zoomOut(): void;
  resetCameraHeadingNorth(): void;
  resetCameraLookDownwards(): void;
  onCameraChange(listener: (cam: Cesium.Camera) => void): () => void;
}>;

const InitGlobusController: React.FC = () => {
  const {viewer} = useCesium();
  const {setGlobus} = useGlobus();
  useEffect(() => {
    if (!viewer) return;

    const cam = viewer.camera;

    function zoom(dir: 'in' | 'out'): void {
      const destination = new Cesium.Cartesian3();
      Cesium.Cartesian3.multiplyByScalar(
        cam.direction,
        5 * (dir === 'in' ? cam.defaultZoomAmount : -cam.defaultZoomAmount),
        destination
      );
      Cesium.Cartesian3.add(cam.position, destination, destination);
      cam.flyTo({
        destination,
        duration: 0.3,
        orientation: {
          heading: cam.heading,
          pitch: cam.pitch,
          roll: 0,
        },
      });
    }

    const globusController: GlobusController = {
      resize() {
        viewer.forceResize();
      },
      zoomIn() {
        zoom('in');
      },
      zoomOut() {
        zoom('out');
      },
      resetCameraHeadingNorth() {
        cam.flyTo({
          destination: cam.position,
          duration: 0.3,
          orientation: {
            heading: Cesium.Math.toRadians(0),
            pitch: cam.pitch,
            roll: 0,
          },
        });
      },
      resetCameraLookDownwards() {
        cam.flyTo({
          destination: cam.position,
          duration: 0.3,
          orientation: {
            heading: cam.heading,
            pitch: Cesium.Math.toRadians(-90),
            roll: 0,
          },
        });
      },
      onCameraChange(listener) {
        listener(cam);
        return cam.changed.addEventListener(() => listener(cam));
      },
    };
    setGlobus((globus) => {
      globus.controller = globusController;
    });
    return () => {
      setGlobus((globus) => (globus.controller = null));
    };
  }, [viewer, setGlobus]);

  return null;
};

export default InitGlobusController;
