import {useCallback} from 'react';

import useLanguage from '../../../hooks/useLanguage';
import {useGlobus} from '../GlobusProvider';

import LayerToggle from './LayerToggle';

type Props = {
  id: string;
};

const GlobusLayer: React.FC<Props> = ({id}) => {
  const lang = useLanguage();
  const {overlayMap, setGlobus} = useGlobus();

  const handleToggle = useCallback(
    () =>
      setGlobus((globus) => {
        const layer = globus.overlayMap?.layers.byId[id];
        if (layer !== undefined) {
          layer.isEnabled = !layer.isEnabled;
        }
      }),
    [id, setGlobus]
  );

  const layer = overlayMap?.layers.byId[id];
  if (layer === undefined) {
    return null;
  }

  return (
    <LayerToggle
      isEnabled={layer.isEnabled}
      onClick={handleToggle}
      label={(lang === 'en' ? layer.labelEn : null) ?? layer.labelDe}
    />
  );
};

export default GlobusLayer;
