import {useCallback} from 'react';

export default function usePropSetter<O, K extends keyof O>(
  setFn: (cb: (object: O) => void) => void,
  key: K
): (value: O[K]) => void {
  return useCallback(
    (value: O[K]) => {
      setFn((object) => {
        object[key] = value;
      });
    },
    [setFn, key]
  );
}
