import {FormEvent, Fragment, useRef} from 'react';

import isNonNullable from '../../../utils/assert';

const invalidRegex = /[^A-Z0-9]/g;

export enum Validity {
  VALID,
  INVALID,
  DOES_NOT_MATCH_MAP,
}

type Props = {
  validity: Validity | undefined;
  onChange: (serial: string) => void;
};

const SerialKeyInput: React.FC<Props> = ({validity, onChange}) => {
  const refs = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)];

  const handleChange = (index: number) => (e: FormEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const selectionStart = target.selectionStart;

    let str = target.value.toUpperCase().replaceAll(invalidRegex, '');
    for (let i = index; i < refs.length && !!str.length; i++) {
      const curTarget = refs[i]?.current;
      if (curTarget) {
        const value = str.substring(0, 4);
        curTarget.value = value + curTarget.value.slice(value.length, 4);
        str = str.substring(4);
      }
    }

    if (target.value.length === 0) {
      const nextTarget = refs[index - 1]?.current;
      if (nextTarget) {
        nextTarget.focus();
      }
    }

    if (target.value.length === 4 && selectionStart === 4) {
      const nextTarget = refs[index + 1]?.current;
      if (nextTarget) {
        nextTarget.focus();
        nextTarget.setSelectionRange(0, 0);
      }
    }
    target.setSelectionRange(selectionStart, selectionStart);

    const serialParts = refs.map((ref) => ref.current?.value).filter(isNonNullable);
    onChange(serialParts.join('-'));
  };

  const onBeforeInput = (e: FormEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    if (target.value.length === 4 && target.selectionStart !== null && !target.value.match(invalidRegex)) {
      const caretPos = target.selectionStart;
      target.value = target.value.slice(0, caretPos) + target.value.slice(caretPos + 1);
      target.setSelectionRange(caretPos, caretPos);
    }
  };

  return (
    <div className="flex justify-center items-center gap-1">
      {refs.map((ref, i) => (
        <Fragment key={i}>
          <input
            onBeforeInput={onBeforeInput}
            ref={ref}
            type="text"
            placeholder="XXXX"
            className={`w-[100px] shrink-0 grow-0 border-2 p-2 text-center rounded-xl outline-diercke-blue
            ${
              validity === Validity.VALID
                ? 'border-[#008000]'
                : validity === Validity.INVALID
                ? 'border-[#FF0000]'
                : validity === Validity.DOES_NOT_MATCH_MAP
                ? 'border-[#FFA500]'
                : ''
            }`}
            onChange={handleChange(i)}
          />
          {i !== refs.length - 1 && <span>–</span>}
        </Fragment>
      ))}
    </div>
  );
};

export default SerialKeyInput;
