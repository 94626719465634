import {useCallback, useEffect, useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';

import Globus from '../globus/Globus';
import GlobusDrawer, {PANEL_WIDTH as DRAWER_PANEL_WIDTH} from '../globus/drawer/GlobusDrawer';
import GlobusDrawerToggle from '../globus/drawer/GlobusDrawerToggle';
import GlobusProvider, {GlobusImage, useGlobus} from '../globus/GlobusProvider';
import {UpdatePositionProps} from '../globus/CameraFlyToCoords';
import GlobusOverlay from '../globus/overlay/GlobusOverlay';
import Legend from '../globus/Legend';
import {useImageData} from '../../api/data';
import Activation from '../globus/activation/Activation';
import useBreakpoints from "../../hooks/useBreakpoints";

type Props = UpdatePositionProps & SetGlobusConfigsProps;

const GlobusPage: React.FC<Props> = ({children: _children, kmzId, img, ...globusProps}) => {
  const breakpoints = useBreakpoints();
  const [isDrawerOpen, setIsToolDrawerOpen] = useState(breakpoints.md);
  const [showLegend, setShowLegend] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  const handleDrawerToggle = useCallback(() => setIsToolDrawerOpen((prev) => !prev), []);

  const {data: imageData} = useImageData(kmzId);

  return (
    <GlobusProvider>
      <SetGlobusConfigs kmzId={kmzId} img={img} />

      {showLogin ? (
        <div className="w-full h-full bg-black/50 grid place-items-center">
          <Activation kmzId={kmzId} onIsActivated={() => setShowLogin(false)} />
        </div>
      ) : (
        <div className="w-full h-full flex flex-row">
          <AnimatePresence initial={false}>
            {isDrawerOpen && (
              <motion.div
                key="drawer"
                className="flex-shrink-0 flex-grow-0"
                initial={{width: 0}}
                animate={{width: DRAWER_PANEL_WIDTH}}
                exit={{width: 0}}
                transition={{type: 'linear'}}
              >
                <GlobusDrawer setShowLegend={setShowLegend} />
              </motion.div>
            )}
          </AnimatePresence>

          <div
            className={`
          flex-grow-0 flex-shrink-0
          flex items-stretch
            h-full bg-white relative z-20
            border-l ${isDrawerOpen ? 'border-l-diercke-blue' : 'border-l-white'}`}
          >
            <div className="absolute inset-y-0 w-[45px] flex flex-col justify-center">
              <div className="max-h-[calc(50vh-60px)] overflow-y-auto no-scrollbar py-1" />
              <div className="z-20 -ml-px">
                <GlobusDrawerToggle onToggle={handleDrawerToggle} isOpen={isDrawerOpen} />
              </div>
              <div className="max-h-[calc(50vh-60px)] overflow-y-auto no-scrollbar py-1" />
              <div className="h-[26px] flex-shrink"></div>
            </div>

            {/* {disableOverlay} */}
          </div>

          <div className="flex-grow flex-shrink h-full">
            <div className="relative border-white w-full h-full border-[10px] overflow-hidden">
              <Globus {...globusProps} />
              {/* <div className="w-full h-full bg-gray-500" /> */}
              <Legend showLegend={showLegend} setShowLegend={setShowLegend} />
              <div className="absolute inset-0 pointer-events-none">
                <GlobusOverlay imageName={imageData?.name} />
              </div>
            </div>
            {/* {disableOverlay} */}
          </div>
        </div>
      )}
    </GlobusProvider>
  );
};

export default GlobusPage;

type SetGlobusConfigsProps = {
  kmzId: null | number;
  img: null | GlobusImage;
};

const SetGlobusConfigs: React.FC<SetGlobusConfigsProps> = ({img, kmzId}) => {
  const {setGlobus} = useGlobus();

  useEffect(() => {
    if (img === null) {
      return;
    }

    setGlobus((globus) => {
      globus.image = img;
    });
  }, [setGlobus, img]);

  useEffect(() => {
    if (kmzId === null) {
      return;
    }

    setGlobus((globus) => {
      if (globus.overlayMap === null) {
        globus.overlayMap = {
          id: kmzId,
          isVisible: true,
          opacity: 1,
          // TODO: remove mock data

          layers: {
            order: [],
            byId: {},
          },

          /*
          layers: {
            order: ['National borders', 'foo1', 'foo2'],
            byId: {
              'National borders': {
                isEnabled: true,
                labelDe: 'Staatsgrenzen',
                labelEn: 'National borders',
              },
              foo1: {
                isEnabled: true,
                labelDe: 'Lorem Ipsum 1',
                labelEn: null,
              },
              foo2: {
                isEnabled: true,
                labelDe: 'Lorem Ipsum 2',
                labelEn: null,
              },
            },
          },
*/
        };
      }
    });
  }, [setGlobus, kmzId]);

  return null;
};
