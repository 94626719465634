import {useCallback, useState} from 'react';

import {PopoverAlign, PopoverPosition, PopoverRect, PopoverState, PositionPopover} from '../components/shared/Popover';

export const getRectForAlignedPosition = (
  position: PopoverPosition,
  contentRect: PopoverRect,
  popoverRect: PopoverRect,
  padding: number,
  align: PopoverAlign
): PopoverRect => {
  const targetMidX = contentRect.left + contentRect.width / 2;
  const targetMidY = contentRect.top + contentRect.height / 2;
  const {width, height} = popoverRect;
  let top: number;
  let left: number;

  switch (position) {
    case 'left':
      top = targetMidY - height / 2;
      left = contentRect.left - padding - width;
      if (align === 'start') {
        top = contentRect.top;
      }
      if (align === 'end') {
        top = contentRect.bottom - height;
      }
      break;
    case 'bottom':
      top = contentRect.bottom + padding;
      left = targetMidX - width / 2;
      if (align === 'start') {
        left = contentRect.left;
      }
      if (align === 'end') {
        left = contentRect.right - width;
      }
      break;
    case 'right':
      top = targetMidY - height / 2;
      left = contentRect.right + padding;
      if (align === 'start') {
        top = contentRect.top;
      }
      if (align === 'end') {
        top = contentRect.bottom - height;
      }
      break;
    default:
      top = contentRect.top - height - padding;
      left = targetMidX - width / 2;
      if (align === 'start') {
        left = contentRect.left;
      }
      if (align === 'end') {
        left = contentRect.right - width;
      }
      break;
  }

  top = Math.round(top);
  left = Math.round(left);

  return {
    left,
    right: left + width,
    top,
    bottom: top + height,
    width,
    height,
  };
};

export interface UsePopoverProps {
  isOpen: boolean;
  childRef: React.MutableRefObject<HTMLElement | undefined>;
  position: PopoverPosition;
  align: PopoverAlign;
  padding: number;
  parentElement?: HTMLElement;

  onPositionPopover(popoverState: PopoverState): void;
}

export const usePopover = ({
  isOpen,
  childRef,
  position,
  parentElement = window.document.body,
  align,
  padding,
  onPositionPopover,
}: UsePopoverProps): {
  updatePosition: PositionPopover;
  popoverElement: HTMLDivElement;
} => {
  const [popoverElement] = useState(() => {
    const element = window.document.createElement('div');
    element.className = 'fixed top-0 left-0';
    return element;
  });

  const updatePosition = useCallback<PositionPopover>(
    ({
      parentRect = parentElement.getBoundingClientRect(),
      childRect = childRef?.current?.getBoundingClientRect(),
      popoverRect = popoverElement.getBoundingClientRect(),
    } = {}) => {
      if (!childRect || !isOpen) {
        return;
      }

      const nextPopoverRect = getRectForAlignedPosition(position, childRect, popoverRect, padding, align);

      popoverElement.style.transform = `translate(${nextPopoverRect.left}px, ${nextPopoverRect.top}px)`;
      popoverElement.style.maxWidth = `${parentRect.width - nextPopoverRect.left}px`;
      popoverElement.style.maxHeight = `${parentRect.height - nextPopoverRect.top}px`;

      onPositionPopover({
        childRect,
        popoverRect: nextPopoverRect,
        parentRect,
        position,
        align,
        padding,
      });
    },
    [parentElement, childRef, popoverElement, position, align, padding, onPositionPopover, isOpen]
  );

  return {
    updatePosition,
    popoverElement,
  };
};
