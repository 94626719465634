import {useTranslation} from 'react-i18next';

import Icon from '../shared/Icon';
import {getLegendeImg} from '../../modules/url';
import Window from '../shared/Window';

import {useGlobus} from './GlobusProvider';

type Props = {
  padding?: [number, number, number, number];
  showLegend: boolean;
  setShowLegend(showLegend: boolean): void;
};

const Legend: React.FC<Props> = ({showLegend, setShowLegend}) => {
  const {t} = useTranslation();
  const {overlayMap} = useGlobus();

  if (overlayMap === null) return null;
  return (
    <Window
      className={`cursor-pointer w-fit flex  max-h-[calc(100%-10px)] max-w-[calc(100%-10px)] z-10 ${
        showLegend ? 'visible' : 'hidden'
      }`}
      padding={[5, 5, 5, 5]}
      defaultX={20}
      defaultY={20}
    >
      <div className="flex flex-col">
        <div className="flex items-center bg-diercke-blue-250 border-b border-diercke-blue">
          <div className="font-bold text-sm p-2 truncate grow">{t('description.legend')}</div>
          <Icon className={'min-w-fit right-0'} onClick={() => setShowLegend(false)} name="Schliesskreuz-01" />
        </div>
        <div className="overflow-auto">
          <img
            className={'object-none min-w-fit min-h-fit '}
            src={getLegendeImg(overlayMap.id)}
            alt={t('description.legend')}
          />
        </div>
      </div>
    </Window>
  );
};

export default Legend;
