import {Context, createContext, useCallback, useContext, useMemo, useState} from 'react';
import {produce, Immutable} from 'immer';

export type ChangeableContextValue<T, N extends string> = Immutable<T> & {
  [K in `set${Capitalize<N>}`]: (cb: (value: T) => void) => void;
};

export type ChangeableContext<T, N extends string> = {
  Context: Context<ChangeableContextValue<T, N>>;
  Provider: React.FC;
  useValue(): ChangeableContextValue<T, N>;
};

export default function makeChangeableContext<T, N extends string>(
  name: N,
  defaultValue: Immutable<T>
): ChangeableContext<T, N> {
  const firstUcName = `${name.charAt(0).toUpperCase()}${name.slice(1)}` as Capitalize<N>;
  const setName = `set${firstUcName}` as const;

  const Context = createContext<ChangeableContextValue<T, N>>({
    ...defaultValue,
    [setName]() {},
  } as ChangeableContextValue<T, N>);
  Context.displayName = `Context${firstUcName}`;

  function useValue(): ChangeableContextValue<T, N> {
    return useContext(Context);
  }

  const Provider: React.FC = ({children}) => {
    const [value, setValue] = useState(defaultValue);
    const handleSet = useCallback((cb: (value: T) => void) => setValue(produce(cb) as any), []);
    const ctxValue = useMemo(
      () => ({...value, [setName]: handleSet} as ChangeableContextValue<T, N>),
      [value, handleSet]
    );
    return <Context.Provider value={ctxValue}>{children}</Context.Provider>;
  };
  Provider.displayName = `ContextProvider${firstUcName}`;

  return {Context, Provider, useValue};
}
