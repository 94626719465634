export default function throttle(fn: Function, time: number = 100) {
  let throttleTimer = false;

  return (...args: any[]) => {
    if (throttleTimer) {
      return;
    }

    throttleTimer = true;
    setTimeout(() => {
      fn(...args);
      throttleTimer = false;
    }, time);
  };
}
