import {cloneElement, ReactElement} from 'react';

import {bgClasses} from './OverlayButton';
import OverlayButtonGroupSpacer from './OverlayButtonGroupSpacer';

export type OptionalReactElement = ReactElement | boolean | null | undefined;

export type OverlayButtonGroupProps = {
  className?: string;
  orientation?: 'x' | 'y';
  children: OptionalReactElement | OptionalReactElement[];
};

export function mapButtonGroupChildren(element: OptionalReactElement, index: number, orientation = 'x') {
  if (element === null || element === undefined || typeof element === 'boolean') {
    return element;
  }

  if (element.type === OverlayButtonGroupSpacer) {
    return cloneElement(element, {
      ...element.props,
      key: index,
      orientation,
    });
  }

  return cloneElement(element, {
    ...element.props,
    key: index,
    orientation,
    grouped: true,
  });
}

const OverlayButtonGroup: React.FC<OverlayButtonGroupProps> = ({
  orientation = 'y',
  children = [],
  className = '',
  ...rest
}) => {
  children = Array.isArray(children) ? children : [children];

  return (
    <div
      className={`flex select-none pointer-events-auto ${
        orientation === 'x' ? 'flex-row' : 'flex-col'
      } ${bgClasses} ${className}`}
      {...rest}
    >
      {children.map((element, index) => mapButtonGroupChildren(element, index, orientation))}
    </div>
  );
};

export default OverlayButtonGroup;
