import {useTranslation} from 'react-i18next';

import {useGlobus} from '../GlobusProvider';

import OverlayButton from './OverlayButton';

// we need to pass some hidden props to the button
const ZoomOutButton: React.FC = (props) => {
  const {t} = useTranslation();
  const {controller} = useGlobus();
  return (
    <OverlayButton onClick={controller?.zoomOut} icon="Minus" title={t('globus:description.zoomOut')} {...props} />
  );
};

export default ZoomOutButton;
