import {MouseEventHandler} from 'react';

import Icon, {IconName} from '../../shared/Icon';

type Props = {
  icon?: IconName;
  label: string;
  title?: string;
  isEnabled?: boolean;
  onClick?: MouseEventHandler;
};

const LayerToggle: React.VFC<Props> = ({icon = 'Auge', label, title = label, isEnabled = true, onClick}) => (
  <button
    role="checkbox"
    aria-checked={isEnabled}
    className="w-full flex hover:bg-diercke-blue-250"
    onClick={onClick}
    title={title}
  >
    <Icon className={`flex-shrink-0 ${isEnabled ? '' : 'opacity-25'}`} name={icon} />
    <span className="flex-grow py-2 text-left">{label}</span>
  </button>
);

export default LayerToggle;
